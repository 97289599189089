import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  PagingPanel,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import {
  PagingState,
  IntegratedPaging,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as styles from './Orders.css';
import { TextField } from '@material-ui/core';

const CustomizedHeader = ({ ...restProps }) => (
  <Table.Row {...restProps} css={styles.tableHeader} />
);
const CustomizedTable = ({ ...restProps }) => (
  <Table.Table {...restProps} css={styles.tableRows} />
);

const CustomizedPanel = ({ ...restProps }) => (
  <PagingPanel.Container {...restProps} css={styles.tablePanel} />
);

@withRouter
@inject('rootStore')
@observer
class OrderLines extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeInput: -1,
      activeName: '',
      searchString: '',
    };
    this.checker = 0;
    this.turn = 1;
  }
  search() {
    this.props.rootStore.ordersStore.fetchCurrentOrderLines(
      this.props.rootStore.ordersStore.currentOrder.number,
      this.state.searchString
    );
    this.checker = 0;
  }

  render() {
    const totalCell = (el) => {
      return <div>{parseFloat(el.value).toFixed(2)}</div>;
    };

    const TotalCellProvider = (props) => {
      return <DataTypeProvider formatterComponent={totalCell} {...props} />;
    };
    const columns = [
      { name: 'description', title: 'Description' },
      { name: 'unit_price', title: 'Unit Price' },
      { name: 'product_item_sell_pack_name', title: 'Pack' },
      { name: 'quantity', title: 'Quantity' },
      { name: 'product_description', title: 'Product Description' },
      { name: 'weight', title: 'Unit Weight, Kg' },
      { name: 'line_tax', title: 'Tax Amount' },
      { name: 'freight_insurance', title: 'Freight Insurance' },
      { name: 'freight_cost', title: 'Freight Cost' },
      { name: 'discount_total', title: 'Discount Total' },
      { name: 'line_total', title: 'Line Total' },
    ];
    const columnWidths = [
      { columnName: 'unit_price', width: 120 },
      { columnName: 'description', width: 320 },
      { columnName: 'quantity', width: 120 },
      { columnName: 'product_item_sell_pack_name', width: 120 },
      { columnName: 'product_description', width: 120 },
      { columnName: 'weight', width: 120 },
      { columnName: 'line_total', width: 120 },
      { columnName: 'line_tax', width: 120 },
      { columnName: 'freight_insurance', width: 120 },
      { columnName: 'freight_cost', width: 120 },
      { columnName: 'discount_total', width: 120 },
    ];
    this.checker = 0;
    return (
      <div css={styles.tableDiv}>
        <div>
          <TextField
            placeholder="Search for Invoice Lines"
            value={this.state.searchString}
            css={styles.textfield}
            className="lines"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                this.search();
              }
            }}
            onChange={(el) => {
              this.setState({
                searchString: el.target.value,
              });
            }}
          ></TextField>
          <button
            css={styles.searchButton}
            className="lines"
            onClick={() => this.search()}
            name="button"
          >
            <i className="fa fa-search"></i>
          </button>
        </div>
        {this.props.rootStore.ordersStore.lines ? (
          <Grid
            rows={
              this.props.rootStore.ordersStore.lines
                ? this.props.rootStore.ordersStore.lines
                : []
            }
            columns={columns}
          >
            <PagingState defaultCurrentPage={0} pageSize={30} />
            <IntegratedPaging />
            <VirtualTable tableComponent={CustomizedTable} />
            <TableColumnResizing
              defaultColumnWidths={columnWidths}
              resizingMode={'widget'}
            />
            <TableHeaderRow rowComponent={CustomizedHeader} />
            <TotalCellProvider
              for={['line_total', 'unit_price', 'line_tax']}
            ></TotalCellProvider>
            <PagingPanel containerComponent={CustomizedPanel} />
          </Grid>
        ) : null}
      </div>
    );
  }
}

export default OrderLines;
