import { observable, action } from 'mobx';
import Axios from 'axios';

class RatesStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }
  @observable rates = [];

  @action fetchRates(date) {
    return Axios.get(
      `${process.env.REACT_APP_API}/rates?date=${date}&organisation=${this.rootStore.currentUserOrg.id}`,
      this.rootStore.config()
    ).then(this.fetchRatesSuccess, this.fetchRatesError);
  }
  @action.bound fetchRatesSuccess(response) {
    response.data.rates.sort((a, b) => {
      const textA = a.name.toUpperCase();
      const textB = b.name.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    this.rates = response.data.rates;

    this.rates.forEach((elem) => {
      elem.previewRate = elem.proccesedRate;
      elem.previewPositive = true;
      elem.previewMarkup = 0;
    });
  }
  @action.bound fetchRatesError(error) {
    console.log(error);
  }

  @action saveRates(promise) {
    const newRates = [];
    this.rates.forEach((elem) => {
      if (elem.previewRate !== elem.proccesedRate) {
        elem.proccesedRate = elem.previewRate;
        elem.markup = elem.previewMarkup;
        elem.positive = elem.previewPositive;
        newRates.push(elem);
      }
    });
    return promise
      ? Axios.post(
          `${process.env.REACT_APP_API}/rates`,
          { rates: newRates },
          this.rootStore.config()
        )
      : Axios.post(
          `${process.env.REACT_APP_API}/rates`,
          { rates: this.rates },
          this.rootStore.config()
        ).then(this.saveRatesSuccess, this.saveRatesError);
  }
  @action.bound saveRatesSuccess(response) {
    console.log(response);
  }
  @action.bound saveRatesError(error) {
    console.log(error);
  }
}

export default RatesStore;
