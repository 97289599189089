import { css } from '@emotion/core';

const mainDiv = css`
  display: flex;
  height: 90%;
  & h1 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-weight: 300 !important;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 32px;
    line-height: 1.1;
    color: inherit;
  }
`;
const exportAllButton = css`
  position: fixed !important;
  bottom: 8px !important;
  z-index: 100 !important;
  left: 10px !important;
`;
const toolBar = css`
  position: absolute;
  background-color: rgb(19, 35, 65);
  color: white;
  border-bottom: 3px solid #5cb85c;
  top: 0px;
  width: 100%;
  height: 50px;
`;
const img = css`
  background-size: 239px 75px;
  background-position: center;
  display: inline-block;
  width: 211px;
  background-image: url(/logo.png);
  @media (max-width: 390px) {
    display: none;
  }
`;
const toolbarButton = css`
  display: inline-block;
  float: right;
  label,
  button {
    outline: none;
    border: none;
    font-size: 14px !important;
    background-color: transparent !important;
    color: rgb(41, 101, 168);
    &:hover {
      color: #23527c;
    }
    @media (max-width: 560px) {
      display: none;
    }
  }
  i {
    color: #337ab7;
    margin-right: 5px;
  }
  height: 50px;
  margin: 1em;
  &.non-right {
    float: none;
    color: white !important;
  }
  &.custom {
    margin-top: 8px;
  }
`;
const toolbarMenu = css`
  display: inline-block;
  height: 100%;
  float: left;
  display: flex;
  i {
    cursor: pointer;
    font-size: 18px;
    color: #fff;
    padding: 13px 5px;
    line-height: 22px;
    position: relative;

    @media (max-width: 390px) {
      margin-left: 30px;
    }
  }
`;

const pageContent = css`
  display: inline-block;
  margin-left: 5px;
  height: 100%;
  margin-top: 60px;
  width: calc(100vw - 215px);
  &.hidden {
    width: 95% !important;
  }
  padding: 0;
`;

const tabContent = css`
  padding: 0.5em;
  background-color: #f3f3f3;
  height: 97%;
  position: relative;
`;

const widget = css`
  margin-botton: 3px 0 0.5em 0;
  padding: 0;
  height: 100%;
  box-shadow: none;
`;

const widgetHeader = css`
  position: relative;
  background-image: linear-gradient(to right, #b8d8e8, #cde2ed);
  color: #555555;
  padding-left: 12px;
  text-align: right;
  &.bg-themeprimary {
    border: 0;
  }
  &.bg-themeprimary > .widget-caption {
    color: #ffffff;
  }

  & > .widget-caption {
    line-height: 34px;
    padding: 0;
    margin: 0;
    float: left;
    text-align: left;
    font-weight: 400 !important;
    font-size: 13px;
  }
`;

const widgetCaption = css`
  widgetHeader & {
    line-height: 34px;
    padding: 0;
    margin: 0;
    float: left;
    text-align: left;
    font-weight: 400 !important;
    font-size: 13px;
  }
`;

const widgetBody = css`
  background-image: linear-gradient(to bottom, #cde2ed, #f3f3f3);
  padding: 8px 8px 0 8px;
  height: 90%;
`;

const clearfix = css`
  clear: both;
  &:before {
    content: ' ';
    display: table;
  }
`;
const footer = css`
  text-align: center;
  height: 50px;
  background-color: rgb(29, 44, 74);
  bottom: 0px;
  position: absolute;
  width: 100%;
`;

const sideBar = css`
  z-index: 1000;
  margin-top: 45px;
  margin-right: 5px;
  position: relative;
  width: 190px;
  &.hidden {
    width: 30px;
  }
  display: inline-block;
`;
const sidebarList = css`
  padding: 0;
  list-style: none;
  margin-left: 5px;
  width: 100%;
  height: 90%;
`;
const subMenu = css`
  z-index: 1000;
  background-color: #fbfbfb;
  box-shadow: inset 0 -4px 4px -2px rgba(0, 0, 0, 0.15);
  &:before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    left: 15px;
    top: 32px;
    bottom: 0;
    border-left: 1px solid #e2e2e2;
  }
  padding: 0;
  &.none {
    display: none;
  }
`;
const listItem = css`
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
  position: relative;
  width: 100%;
  a {
    display: inline-block;
    height: 100%;
    width: 100%;
    border-top: 1px solid white;
    border-bottom: 1px solid #e8e8e8;
    background-color: rgb(242, 242, 242);
    line-height: 30px;
    margin: 0;
    padding: 0;
    text-shadow: none !important;
    font-size: 12px;
    &:hover {
      background-color: white;
      line-height: 28px;
      border-left: 2px solid rgb(70, 156, 49);
    }
    &.active {
      border-left: 2px solid rgb(70, 156, 49);
      background-color: white;
    }
  }
`;
const listLabel = css`
  &.hidden {
    display: none;
  }
  &.active {
    background: #fbfbfb;
    border-left: 1px solid #f3f3f3;
    z-index: 100;
    line-height: 30px;
    padding-left: 24px;
    display: block;
    width: 190px;
    margin-top: -29px;
    margin-left: 27px;
    border-bottom: 1px solid #f3f3f3;
    box-shadow: none;
  }
`;
const listIcon = css`
  margin-right: 5px;
  display: inline-block;
  vertical-align: middle;
  min-width: 20px;
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  margin-top: -2px;
  &.hidden {
    display: none;
  }
  &.left {
    margin-top: 9px;
    float: right;
    transform: rotate(-90deg);
  }
  &.rotate {
    margin-top: 9px;
    float: right;
    transform: none;
  }
`;
const subItem = css`
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
  position: relative;
  width: 100%;
  a {
    text-decoration: none;
    color: black;
  }
`;
const subTitle = css`
  padding-left: 35px !important;
`;
const hiddenMenu = css`
  display: none;
  width: 190px;
  background: #fbfbfb;
  margin-top: -2px;
  margin-left: 29px;
  border-left: 1px solid #f3f3f3;
  line-height: 30px;
  a {
    line-height: 30px;
    padding-left: 24px;
    background: #fbfbfb;
    text-decoration: none;
    color: black;
    &:hover {
      background-color: white;
      padding-left: 22px;
      border-left: 2px solid rgb(70, 156, 49);
    }
  }

  &.active {
    display: block;
    position: absolute;
  }
`;

export {
  widgetHeader,
  widgetCaption,
  widgetBody,
  clearfix,
  footer,
  sideBar,
  sidebarList,
  subMenu,
  listItem,
  listLabel,
  listIcon,
  subItem,
  subTitle,
  hiddenMenu,
  widget,
  tabContent,
  pageContent,
  toolbarMenu,
  toolbarButton,
  exportAllButton,
  img,
  toolBar,
  mainDiv,
};
