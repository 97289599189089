import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  PagingPanel,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { PagingState, IntegratedPaging } from '@devexpress/dx-react-grid';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
import {  Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const itemMatchingBackendUrl =
  process.env.REACT_APP_HOTEL_ITEM_MATCHING_BACKEND;

/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as styles from './Suppliers.css';
import Button from '@material-ui/core/Button';
const boolCell = (el) => {
  return el.value ? (
    <i className="fa fa-check"></i>
  ) : (
    <i className="fa fa-times"></i>
  );
};

const BoolCellProvider = (props) => (
  <DataTypeProvider formatterComponent={boolCell} {...props} />
);
const editCell = () => <i css={supCell} className="fa fa-edit"></i>;
const EditCellProvider = (props) => (
  <DataTypeProvider formatterComponent={editCell} {...props} />
);
const CustomizedHeader = ({ ...restProps }) => (
  <Table.Row {...restProps} css={styles.tableHeader} />
);
const CustomizedTable = ({ ...restProps }) => (
  <Table.Table {...restProps} css={styles.tableRows} />
);

const CustomizedPanel = ({ ...restProps }) => (
  <PagingPanel.Container {...restProps} css={styles.tablePanel} />
);

@withRouter
@inject('rootStore')
@observer
class UploadFileArea extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedFile: undefined,
      isFilePicked: false,
      isLoading: false,
      availableFiles: [],
    };
  }

  componentDidMount() {
    const url = `${itemMatchingBackendUrl}listOutputFiles`;
    fetch(url, {
      method: 'GET',
    })
      .then((result) => {
        result.json().then((data) => {
          this.setState({ availableFiles: data.available_files });
        });
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  render() {
    const columns = [
      { name: 'supplierNumber', title: 'Supplier Number' },
      { name: 'supplierBNumber', title: 'Supplier Business Number' },
      { name: 'supplier', title: 'Supplier' },
      { name: 'preferredSupplier', title: 'Preferred Supplier' },
      { name: 'enable', title: 'Enable' },
      { name: 'isBillable', title: 'Is Billable' },
      { name: 'purchaserPricePreferred', title: 'Purchaser Price Preferred' },
      { name: 'discountRate', title: 'Discount Rate' },
      { name: 'emailAddress', title: 'Paperless Invoicing Email Address' },
      { name: 'punchoutEnabled', title: 'Punchout Enabled' },
      { name: 'defaultDepartment', title: 'Default Department' },
      { name: 'defaultAccount', title: 'Default Account' },
      { name: 'defaultCategory', title: 'Default Category' },
      { name: 'locationCode', title: 'Supplier Location Code' },
      {
        name: 'invoicingContacts',
        title: 'Supplier Paperless Invoicing Contacts',
      },
      { name: 'formatMapped', title: 'Invoice Format Mapped' },
      { name: 'contact', title: 'Supplier Contact' },
      { name: 'terms', title: 'Payment Terms' },
      { name: 'promote', title: 'Promote' },
    ];
    const columnWidths = [
      { columnName: 'supplierNumber', width: 120 },
      { columnName: 'supplierBNumber', width: 120 },
      { columnName: 'supplier', width: 240 },
      { columnName: 'preferredSupplier', width: 80 },
      { columnName: 'enable', width: 80 },
      { columnName: 'isBillable', width: 80 },
      { columnName: 'purchaserPricePreferred', width: 80 },
      { columnName: 'discountRate', width: 80 },
      { columnName: 'emailAddress', width: 120 },
      { columnName: 'punchoutEnabled', width: 80 },
      { columnName: 'defaultDepartment', width: 80 },
      { columnName: 'defaultAccount', width: 80 },
      { columnName: 'defaultCategory', width: 80 },
      { columnName: 'locationCode', width: 80 },
      {
        columnName: 'invoicingContacts',
        width: 80,
      },
      { columnName: 'formatMapped', width: 80 },
      { columnName: 'contact', width: 80 },
      { columnName: 'terms', width: 80 },
      { columnName: 'promote', width: 80 },
    ];

    const supplierCell = (el) => {
      const link = `${itemMatchingBackendUrl}getOutputFile?name=`;
      return (
        <Link to={`${link}${el}`} css={styles.supCell}>
          <i css={styles.nameIcon} className="fa fa-star"></i>
          {el.value}
        </Link>
      );
    };

    const changeHandler = (event) => {
      this.state.selectedFile = event.target.files[0];
      this.state.isFilePicked = true;
    };

    const renderRow = (value) => {
      const url = `${itemMatchingBackendUrl}getOutputFile?name=${value}`;
      return (
        <tbody>
          <tr>
            <td>
              <a href={url}>{value}</a>
            </td>
          </tr>
        </tbody>
      );
    };

    const handleSubmission = () => {
      const url = `${itemMatchingBackendUrl}downloadCatalogue`;

      const formData = new FormData();

      console.log(' this.state.selectedFile:', this.state.selectedFile);
      formData.append('File', this.state.selectedFile);

      fetch(url, {
        method: 'POST',
        body: formData,
      })
        .then((response) => response.json())
        .then((result) => {
          console.log('Success:', result);
          this.setState({
            matchingStartedSuccessfully: true,
            matchingStartFailed: false,
            matchingError: '',
          })
        })
        .catch((error) => {
          console.error('Error:', error);
          this.setState({
            matchingStartedSuccessfully: false,
            matchingStartFailed: true,
            matchingError: JSON.stringify(error),
          })
        });
    };


    const handleCloseSucess = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      this.setState({ matchingStartedSuccessfully: false });
    };

    const handleCloseError= (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      this.setState({ matchingStartFailed: false });
    };

    return (
      <div>
        <div css={styles.tableDiv}>
          <table>
            <thead>
              <tr>
                <th>File Name</th>
              </tr>
            </thead>
            {this.state.availableFiles &&
              this.state.availableFiles.map(renderRow)}
          </table>
          <input type="file" name="file" onChange={changeHandler} />
          <Button
            color="primary"
            disabled={this.state.isFilePicked}
            onClick={handleSubmission}
            style={{ height: '52px', width: '172px' }}
            variant="contained"
          >
            {this.state.isLoading ? (
              <CircularProgress size={25} />
            ) : (
              'Start Matching'
            )}
          </Button>
        </div>
        <Snackbar
          open={this.state.matchingStartedSuccessfully}
          onClose={handleCloseSucess}
          autoHideDuration={6000}
        >
          <Alert onClose={handleCloseSucess} severity="success" sx={{ width: '100%' }} >
            Matching Process started
          </Alert>
        </Snackbar>
        <Snackbar
          open={this.state.matchingStartFailed}
          onClose={handleCloseError}
          autoHideDuration={6000}
        >
          <Alert onClose={handleCloseError} severity="success" sx={{ width: '100%' }} >
            Error when starting the process: {this.state.matchingError}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default UploadFileArea;
