import React from 'react';
import { withRouter } from 'react-router-dom';

/** @jsx jsx */
import * as styles from './Main.css';
import { css, jsx } from '@emotion/core';

@withRouter
class MainWidget extends React.PureComponent {
  render() {
    return (
      <div>
        <div css={styles.widgetHeader} className="bg-themeprimary">
          <span css={styles.widgetCaption} className="widget-caption">
            <span>Homepage</span>
          </span>
        </div>
        <div
          css={css`
            ${styles.widgetBody} ${styles.clearfix}
          `}
        >
          <h1>Welcome to Purchase Plus</h1>
          <p>
            You can find your navigation on the left hand side. If you
            navigation menu is empty please you can always email us at{' '}
            <a href="mailto:help@marketboomer.com">help@marketboomer.com.</a>
          </p>
        </div>
      </div>
    );
  }
}

export default MainWidget;
