import { observable } from 'mobx';

class UiStateStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }
  @observable menuLabels = [];
  @observable isMenuTextVisible = window.innerWidth > 1000 ? true : false;
  @observable activeMenu = -1;
  @observable showPopup = false;
  @observable showSubmit = false;
  @observable popupMessage = '';
  @observable spinner = false;
}

export default UiStateStore;
