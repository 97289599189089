import { observable, action, decorate } from 'mobx';
import Cookie from 'mobx-cookie';
import Axios from 'axios';

import AuthStore from './auth';
import UiStateStore from './uistate';
import RatesStore from './rates';
import SuppliersStore from './suppliers';
import OrdersStore from './orders';
import InvoicesStore from './invoices';
import ReceiversStore from './receivers';
export default class RootStore {
  isAuthenticated = false;
  currentUser = {};
  currentUserOrg = {};
  token = new Cookie('session_token');
  appLoaded = false;

  constructor() {
    this.authStore = new AuthStore(this);
    this.uiStateStore = new UiStateStore(this);
    this.ratesStore = new RatesStore(this);
    this.suppliersStore = new SuppliersStore(this);
    this.ordersStore = new OrdersStore(this);
    this.invoicesStore = new InvoicesStore(this);
    this.receiversStore = new ReceiversStore(this);
    this.isAuthenticated = !!this.token.value;
    if (this.token.value) {
      this.getCurrentUser(this.token.value);
    }
  }

  setToken(token) {
    if (token) {
      this.token.set(token);
      this.isAuthenticated = !!this.token;
    }
  }

  clearToken() {
    this.token.remove();
  }

  setAppLoaded() {
    this.appLoaded = true;
  }

  getCurrentUser(token) {
    return Axios.get(
      `${process.env.REACT_APP_API}/user?token=${token}`,
      this.config()
    ).then(this.getUserSuccess, this.getUserError);
  }

  getUserSuccess(response) {
    this.currentUser = response.data.user;
    this.getCurrentUserOrg();
    this.isAuthenticated = true;
  }

  getUserError() {
    this.isAuthenticated = false;
    this.token.remove();
  }

  getCurrentUserOrg() {
    return Axios.get(
      `${process.env.REACT_APP_API}/currentOrg`,
      this.config()
    ).then(this.getCurrentUserOrgSuccess, this.getCurrentUserOrgError);
  }

  getCurrentUserOrgSuccess(response) {
    this.currentUserOrg = response.data.org;
    this.uiStateStore.menuLabels = this.currentUserOrg.is_a_supplier
      ? [
          {
            title: 'Purchase Order',
            submenu: [{ name: 'View PO', link: '/home/orders' }],
          },
          {
            title: 'Update Invoice',
            submenu: [{ name: 'View Invoices', link: '/home/invoices' }],
          },
          {
            title: 'Upload new catalogue',
            submenu: [],
          },
          {
            title: 'View Supplier',
            submenu: [
              { name: 'My Catalogues', link: '/home/suppliers/catalogues' },
            ],
          },
        ]
      : [
          {
            title: 'Update currency',
            submenu: [{ name: 'Rates', link: '/home/rates' }],
          },
          {
            title: 'Purchase Order',
            submenu: [{ name: 'View PO', link: '/home/orders' }],
          },
          {
            title: 'Update Invoice',
            submenu: [{ name: 'View Invoices', link: '/home/invoices' }],
          },
          {
            title: 'View Suppliers',
            submenu: [{ name: 'Suppliers', link: '/home/suppliers' }],
          },
          {
            title: 'Match Hotel Items',
            submenu: [{ name: 'MatchHotelItems', link: '/home/matching' }],
          },
        ];
    if (this.currentUserOrg.is_a_supplier) {
      this.suppliersStore.fetchSupplierCatalogues();
    }
  }

  getCurrentUserOrgError(response) {
    console.log(response);
  }

  config = () => {
    const token = this.token.value;
    return {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
  };
}

decorate(RootStore, {
  appLoaded: observable,
  isAuthenticated: observable,
  currentUser: observable,
  currentUserOrg: observable,
  getCurrentUser: action,
  getUserSuccess: action.bound,
  getUserError: action.bound,
  getCurrentUserOrg: action,
  getCurrentUserOrgSuccess: action.bound,
  getCurrentUserOrgError: action.bound,
  setToken: action,
  clearToken: action,
  setAppLoaded: action,
});
