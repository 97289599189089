import React from 'react';
import { withRouter } from 'react-router-dom';

@withRouter
class NotFound extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: this.props.error,
    };
  }
  render() {
    return this.state.error ? (
      <div>{this.state.error}</div>
    ) : (
      <div>Not Found</div>
    );
  }
}

export default NotFound;
