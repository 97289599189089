import { observable, action } from 'mobx';
import Axios from 'axios';

class AuthStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable inProgress = false;
  @observable errors = undefined;

  @observable values = {
    email: '',
    password: '',
  };

  @action setEmail(email) {
    this.values.email = email;
  }

  @action setPassword(password) {
    this.values.password = password;
  }

  @action reset() {
    this.values.email = '';
    this.values.password = '';
  }
  @action changeRole() {
    Axios.post(
      `${process.env.REACT_APP_API}/role`,
      null,
      this.rootStore.config()
    ).then((res) => {
      this.rootStore.getCurrentUserOrg();
    });
  }
  @action login() {
    const emailRegExp = /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (emailRegExp.test(this.values.email)) {
      return Axios.post(`${process.env.REACT_APP_API}/login`, {
        email: this.values.email,
        password: this.values.password,
      });
    } else {
      return Promise.reject({
        message: 'Please enter a valid email address',
      });
    }
  }

  @action logout() {
    return Axios.delete(
      `${process.env.REACT_APP_API}/logout`,
      this.rootStore.config()
    );
  }
}

export default AuthStore;
