import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  PagingPanel,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import {
  PagingState,
  IntegratedPaging,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as styles from './Suppliers.css';

const CustomizedHeader = ({ ...restProps }) => (
  <Table.Row {...restProps} css={styles.tableHeader} />
);
const CustomizedTable = ({ ...restProps }) => (
  <Table.Table {...restProps} css={styles.tableRows} />
);

const CustomizedPanel = ({ ...restProps }) => (
  <PagingPanel.Container {...restProps} css={styles.tablePanel} />
);

@withRouter
@inject('rootStore')
@observer
class CataloguesTable extends React.PureComponent {
  render() {
    const columns = [
      { name: 'title', title: 'Name' },
      { name: 'enabled', title: 'Enabled purchasers' },
      { name: 'updated_at', title: 'Updated at' },
      { name: 'buttons', title: ' ' },
    ];
    const columnWidths = [
      { columnName: 'title', width: 400 },
      { columnName: 'enabled', width: 420 },
      { columnName: 'updated_at', width: 120 },
      { columnName: 'buttons', width: 120 },
    ];

    const nameCell = (el) => {
      const link = `/home/${el.row.p_id}/catalogue`;
      return (
        <Link
          to={link}
          onClick={() =>
            this.props.rootStore.suppliersStore.setCurrentSupplierCatalogue(
              el.row.p_id
            )
          }
        >
          {el.value}
        </Link>
      );
    };

    const NameCellProvider = (props) => {
      return <DataTypeProvider formatterComponent={nameCell} {...props} />;
    };
    return (
      <div css={styles.tableDiv}>
        <Grid
          rows={
            this.props.rootStore.suppliersStore.currentSupplierCatalogues
              ? this.props.rootStore.suppliersStore.currentSupplierCatalogues
              : []
          }
          columns={columns}
        >
          <PagingState defaultCurrentPage={0} pageSize={10} />
          <IntegratedPaging />
          <VirtualTable tableComponent={CustomizedTable} />
          <NameCellProvider for={['title']}></NameCellProvider>
          <TableColumnResizing
            defaultColumnWidths={columnWidths}
            resizingMode={'widget'}
          />
          <TableHeaderRow rowComponent={CustomizedHeader} />
          <PagingPanel containerComponent={CustomizedPanel} />
        </Grid>
      </div>
    );
  }
}

export default CataloguesTable;
