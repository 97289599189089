import React from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Select, MenuItem, TextField } from '@material-ui/core';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as styles from './Suppliers.css';
import SuppliersTable from './SuppliersTable';
import CataloguesTable from './CataloguesTable';
import PrivateRoute from '../privateRoute/PrivateRoute';
import { withLastLocation } from 'react-router-last-location';
@withRouter
@inject('rootStore')
@observer
class Suppliers extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchString: '',
    };
    this.search = this.search.bind(this);
  }
  componentDidUpdate() {
    if (
      this.props.lastLocation &&
      this.props.lastLocation.pathname !== '/home/suppliers' &&
      this.props.location.pathname === '/home/suppliers'
    ) {
      this.props.lastLocation.pathname = '/home/suppliers';
      this.props.rootStore.suppliersStore.currentSupplier = {
        supplier_name: 'none',
      };
      this.setState({
        searchString: '',
      });
      this.props.rootStore.suppliersStore.fetchSuppliers(
        this.props.rootStore.currentUserOrg.p_id
      );
    }
    if (
      this.props.lastLocation &&
      this.props.lastLocation.pathname === '/home/suppliers' &&
      this.props.location.pathname === '/home/suppliers/catalogues'
    ) {
      this.props.lastLocation.pathname = '/home/suppliers/catalogues';
      this.setState({
        searchString: '',
      });
    }
  }
  componentDidMount() {
    this.props.rootStore.suppliersStore.setCurrentSupplier({
      supplier_name: 'none',
    });
    this.props.rootStore.suppliersStore.fetchSuppliers(
      this.props.rootStore.currentUserOrg.p_id
    );
  }

  search() {
    if (this.props.location.pathname !== '/home/suppliers/catalogues') {
      this.props.rootStore.suppliersStore.fetchSuppliers(
        this.props.rootStore.currentUserOrg.p_id,
        this.state.searchString
      );
    } else {
      this.props.rootStore.suppliersStore.fetchSupplierCatalogues(
        this.state.searchString
      );
    }
  }
  render() {
    const textfield = css`
      width: 190px;
      margin-top: 20px !important;
      margin-bottom: 20px !important;
      margin-left: 50px;
      background: white;
      min-width: ${this.props.rootStore.currentUserOrg.is_a_purchaser
        ? '50% !important'
        : '90% !important'};
    `;
    return (
      <div css={styles.mainDiv}>
        <div css={styles.buttons}>
          {this.props.rootStore.currentUserOrg.is_a_purchaser ? (
            <Select
              css={styles.select}
              value={
                this.props.rootStore.suppliersStore.currentSupplier
                  .supplier_name
              }
              onChange={(val) => {
                this.props.rootStore.suppliersStore.setCurrentSupplier(
                  val.target.value
                );
                this.props.history.replace('/home/suppliers/catalogues');
              }}
            >
              <MenuItem disabled value={'none'}>
                Select supplier
              </MenuItem>
              {this.props.rootStore.suppliersStore.suppliers.length > 0
                ? this.props.rootStore.suppliersStore.suppliers.map((elem) => (
                    <MenuItem
                      value={elem.supplier_name}
                      key={elem.supplier_name}
                    >
                      {elem.supplier_name}
                    </MenuItem>
                  ))
                : null}
            </Select>
          ) : null}
          <TextField
            value={this.state.searchString}
            css={textfield}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                this.search();
              }
            }}
            id="name"
            placeholder="Name"
            onChange={(el) => {
              this.setState({
                searchString: el.target.value,
              });
            }}
          />
          <button
            onClick={() => this.search()}
            css={styles.searchButton}
            name="button"
          >
            <i className="fa fa-search"></i>
          </button>
        </div>

        <Switch>
          <PrivateRoute
            mustBePurchaser={true}
            isAuthenticated={this.props.rootStore.isAuthenticated}
            currentUser={this.props.rootStore.currentUser}
            org={this.props.rootStore.currentUserOrg}
            exact
            path="/home/suppliers"
            component={SuppliersTable}
          ></PrivateRoute>
          <Route exact path="/home/suppliers/catalogues">
            <CataloguesTable></CataloguesTable>
          </Route>
        </Switch>
      </div>
    );
  }
}

export default withLastLocation(Suppliers);
