import { css } from '@emotion/core';
const flexBody = css`
  overflow: auto;
  padding-left: 10px;
  height: 70%;
  background-color: #cde2ec;
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
`;
const flexColumn = css`
  justify-content: inherit !important;
  width: 40%;
  display: flex;
  flex-direction: column;
  color: black;
`;
const totalBar = css`
  display: flex;
  padding-left: 25px;
  padding-right: 25px;
  justify-content: space-between;
  background-color: #cde2ec;
  color: black;
  border: 1px solid #5fa2dd;
`;
const mainDiv = css`
  * {
    font-size: 11px !important;
  }
  h1 {
    font-size: 32px !important;
    padding-left: 5px;
  }
  height: 90%;
  &.small {
    height: 60%;
  }
`;
const margin = css`
  margin-top: 16px !important;
  margin-bottom: 8px !important;
`;
const columnItem = css`
  display: flex;
  align-items: center;
  max-height: 20%;
  div {
    b {
      color: red;
    }
    display: inline-block;
    width: 40%;
    color: #404040;
    font: 300 13px/17px 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana,
      sans-serif;
  }
  input {
    background-color: #fff;
    font: 300 13px/21px 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana,
      sans-serif;
  }
  p {
    max-width: 50%;
    margin-left: 10px;
    display: inline-block;
  }
  textarea {
    padding: 5px 10px 4px;
    background-color: #fff;
    font: 300 13px/21px 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana,
      sans-serif;
    width: 50%;
    display: inline-block;
    resize: none;
  }
`;
const buttons = css`
  position: fixed !important;
  bottom: 8px !important;
  z-index: 100 !important;
  right: 150px !important;
  button {
    width: 150px;
    margin-right: 20px;
  }
`;

const tableRows = css`
  & tbody tr:nth-of-type(odd) {
    background: white !important;
  }
  & tbody tr:nth-of-type(even) {
    background: #f6f6f6 !important;
  }
`;
const tableHeader = css`
  border-right: 1px solid #d0d0d0;
  color: #404040;
  font: 400 13px/19px 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana,
    sans-serif;
  outline: 0;
  background-color: #fff;
`;

const saveLine = css`
  position: fixed !important;
  bottom: 8px !important;
  z-index: 100 !important;
  right: 1% !important;
`;

const tableDiv = css`
  background-image: linear-gradient(to bottom, #cde2ed, #f3f3f3);
  height: 60%;
  div:nth-of-type(2n) {
    max-height: 100% !important;
  }
`;
const cCell = css`
  width: 70% !important;
  text-overflow: ellipsis;
`;

const tablePanel = css`
  justify-content: flex-start !important;
`;

const searchButton = css`
  background: rgb(87, 181, 227);
  border: none;
  height: 25px;
  margin-top: 15px !important;
  margin-bottom: 8px !important;
  width: 10%;
  i {
    color: white;
  }
  &.lines {
    width: 19%;
  }
`;
const toolbar = css`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  background: #cde2ed;
`;
const textfield = css`
  margin-top: 15px !important;
  margin-right: 5px !important;
  input {
    padding-left: 5px;
    background: white;
    font-size: 10px;
  }
  &.lines {
    width: 80%;
    margin-left: 5px !important;
  }
`;
const checkboxes = css`
  display: flex;
  background: #cde2ed;
  div {
    display: flex;
    margin: 5px;
  }
`;
const statusBlock = css`
  display: flex;
  div {
    margin-right: 5px;
  }
  background: #cde2ed;
  padding-left: 10px;
  align-items: baseline;
`;
const sortBlock = css`
  display: flex;
  align-items: baseline;
`;
const fields = css`
  margin: 5px;
  margin-right: 5px !important;
  background: white;
  input {
    padding-left: 5px;
    font-size: 10px;
  }
  &.fixed {
    padding: 5px;
    width: 120px !important;
    margin-top: 15px;
    height: 25px;
  }
`;
const invoiceMainDiv = css`
  height: 90%;
`;
const clearButton = css`
  background: rgb(152, 159, 162);
  border: none;
  margin-top: 15px !important;
  margin-bottom: 8px !important;
  width: 5%;
  i {
    color: white;
  }
`;
const exportButton = css`
  position: fixed !important;
  bottom: 8px !important;
  z-index: 100 !important;
  right: 140px !important;
`;
const exportAllButton = css`
  position: fixed !important;
  bottom: 8px !important;
  z-index: 100 !important;
  right: 10px !important;
`;

export {
  exportAllButton,
  exportButton,
  saveLine,
  columnItem,
  totalBar,
  checkboxes,
  flexColumn,
  margin,
  sortBlock,
  statusBlock,
  flexBody,
  buttons,
  invoiceMainDiv,
  mainDiv,
  tableRows,
  tableHeader,
  clearButton,
  searchButton,
  fields,
  cCell,
  tablePanel,
  textfield,
  tableDiv,
  toolbar,
};
