const mapper = {
  MVR: 'Maldivian rufiyaa',
  JPY: 'Japanese yen',
  SGD: 'Singapore dollar',
  CNY: 'Renminbi',
  THB: 'Thai baht',
  VND: 'Vietnamese dong',
  AUD: 'Australian dollar',
  AED: 'United Arab Emirates dirham',
  LKR: 'Sri Lankan rupee',
  INR: 'Indian rupee',
  EUR: 'Euro',
  IDR: 'Indonesian rupiah',
  GBP: 'Pound sterling',
};
export const currencyMapper = (cyrrencies) => {
  if (cyrrencies && cyrrencies.length > 0) {
    cyrrencies.forEach((cyrrency) => {
      cyrrency.full = `${cyrrency.name}(${mapper[cyrrency.name]})`;
    });
  }
  return cyrrencies;
};
