import { observable, action } from 'mobx';
import Axios from 'axios';
import { currencyMapper } from '../helpers/currencyMapper';

class OrdersStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }
  @observable orders = [];
  @observable updatedOrders = [];
  @observable availableRates = [];
  @observable idsForReport = [];
  @observable currentOrder = {};
  @observable ordersCount = 0;
  @observable ordersTablePage = 0;
  @observable lines = [];
  @observable updatedIdsForReport = [];
  @observable preferredRate = {};

  @action setCurrentOrder(value) {
    let query = `${process.env.REACT_APP_API}/order?number=${value}`;
    return Axios.get(query, this.rootStore.config()).then(
      this.currentOrderSuccess,
      this.currentOrderError
    );
  }
  @action.bound currentOrderSuccess(response) {
    this.currentOrder = response.data.order;
    this.fetchCurrentOrderLines(this.currentOrder.p_id);
  }
  @action.bound currentOrderError(error) {
    console.log(error);
  }
  @action fetchCurrentOrderLines(value, search) {
    let query = `${process.env.REACT_APP_API}/order/lines?id=${value}`;
    if (search) {
      query += `&search=${search}`;
    }
    return Axios.get(query, this.rootStore.config()).then(
      this.fetchCurrentOrderSuccess,
      this.fetchCurrentOrderError
    );
  }
  @action.bound fetchCurrentOrderSuccess(response) {
    this.lines = response.data.lines;
  }
  @action.bound fetchCurrentOrderError(error) {
    console.log(error);
  }
  @action fetchOrders(id, search, sf, st, df, dt, skip, count) {
    let query = `${process.env.REACT_APP_API}/orders`;
    if (this.rootStore.currentUserOrg.is_a_supplier) {
      query += `?name=${id}`;
    } else {
      query += `?id=${id}`;
    }
    if (count) {
      query += `&count=${count}`;
    }
    if (search) {
      query += `&search=${search}`;
    }
    if (sf) {
      query += `&sf=${sf}`;
    }
    if (st) {
      query += `&st=${st}`;
    }
    if (df) {
      query += `&df=${df}`;
    }
    if (dt) {
      query += `&dt=${dt}`;
    }
    if (skip) {
      query += `&skip=${skip}`;
    }
    return Axios.get(query, this.rootStore.config()).then(
      this.fetchOrdersSuccess,
      this.fetchOrdersError
    );
  }
  @action.bound fetchOrdersSuccess(response) {
    this.updatedOrders = response.data.orders;
    if (this.preferredRate && this.preferredRate.rate) {
      this.updatedOrders.forEach((order) => {
        order.invoiced_value_favorite = (
          order.invoiced_value * this.preferredRate.rate
        ).toFixed(2);
        order.total_value_favorite = (
          order.total_value * this.preferredRate.rate
        ).toFixed(2);
      });
    }
  }
  @action.bound fetchOrdersError(error) {
    console.log(error);
  }
  @action getReport(id, all) {
    let query = `${process.env.REACT_APP_API}/orders/generate`;
    if (this.rootStore.currentUserOrg.is_a_supplier) {
      query += `?name=${id}`;
    } else {
      query += `?id=${id}`;
    }
    if (all) {
      const idsForReport = [];
      this.rootStore.ordersStore.orders.forEach((order) => {
        idsForReport.push(order.id);
      });
      idsForReport.sort();
      return Axios.post(
        query,
        { ids: idsForReport },
        { ...this.rootStore.config(), responseType: 'blob' }
      ).then(this.getReportSuccess, this.getReportError);
    }
    return Axios.post(
      query,
      { ids: this.idsForReport },
      { ...this.rootStore.config(), responseType: 'blob' }
    ).then(this.getReportSuccess, this.getReportError);
  }
  @action getUpdatedReport(id, all) {
    let query = `${process.env.REACT_APP_API}/orders/generate/updated`;
    if (this.rootStore.currentUserOrg.is_a_supplier) {
      query += `?name=${id}`;
    } else {
      query += `?id=${id}`;
    }
    if (all) {
      const idsForReport = [];
      this.rootStore.ordersStore.updatedOrders.forEach((order) => {
        idsForReport.push(order.id);
      });
      idsForReport.sort();
      return Axios.post(
        query,
        { ids: idsForReport },
        { ...this.rootStore.config(), responseType: 'blob' }
      ).then(this.getReportSuccess, this.getReportError);
    }
    return Axios.post(
      query,
      { ids: this.updatedIdsForReport },
      { ...this.rootStore.config(), responseType: 'blob' }
    ).then(this.getReportSuccess, this.getReportError);
  }
  @action.bound getReportSuccess(response) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    const utc = new Date(new Date().toJSON().slice(0, 10).replace(/-/g, '-'));
    const fileName = `PO_${utc}.csv`;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  @action.bound getReportError(error) {
    console.log(error);
  }
  @action fetchUpdatedOrders(id, search, sf, st, df, dt, skip, count) {
    let query = `${process.env.REACT_APP_API}/orders/updated`;
    if (this.rootStore.currentUserOrg.is_a_supplier) {
      query += `?name=${id}`;
    } else {
      query += `?id=${id}`;
    }
    if (count) {
      query += `&count=${count}`;
    }
    if (search) {
      query += `&search=${search}`;
    }
    if (sf) {
      query += `&sf=${sf}`;
    }
    if (st) {
      query += `&st=${st}`;
    }
    if (df) {
      query += `&df=${df}`;
    }
    if (dt) {
      query += `&dt=${dt}`;
    }
    if (skip) {
      query += `&skip=${skip}`;
    }
    return Axios.get(query, this.rootStore.config()).then(
      this.fetchUpdatedOrdersSuccess,
      this.fetchUpdatedOrdersError
    );
  }
  @action recalculateOrders() {
    if (this.preferredRate && this.preferredRate.rate) {
      this.updatedOrders.forEach((order) => {
        if (order.currency === 'USD') {
          order.invoiced_value_usd = order.invoiced_value;
          order.total_value_usd = order.total_value;
          order.invoiced_value_favorite = (
            order.invoiced_value * +this.preferredRate.rate
          ).toFixed(2);
          order.total_value_favorite = (
            order.total_value * +this.preferredRate.rate
          ).toFixed(2);
        } else {
          order.invoiced_value_usd = (
            order.invoiced_value / order.rate
          ).toFixed(2);
          order.total_value_usd = (order.total_value / order.rate).toFixed(2);
          order.invoiced_value_favorite = (
            (order.invoiced_value / order.rate) *
            +this.preferredRate.rate
          ).toFixed(2);
          order.total_value_favorite = (
            (order.total_value / order.rate) *
            +this.preferredRate.rate
          ).toFixed(2);
        }
      });
    }
  }
  @action.bound fetchUpdatedOrdersSuccess(response) {
    if (response.data.count) {
      this.ordersCount = response.data.count;
    }
    if (this.preferredRate && this.preferredRate.rate) {
      response.data.updatedOrders.forEach((order) => {
        if (order.currency === 'USD') {
          order.invoiced_value_usd = order.invoiced_value;
          order.total_value_usd = order.total_value;
          order.invoiced_value_favorite = (
            order.invoiced_value * +this.preferredRate.rate
          ).toFixed(2);
          order.total_value_favorite = (
            order.total_value * +this.preferredRate.rate
          ).toFixed(2);
        } else {
          order.invoiced_value_usd = (
            order.invoiced_value / order.rate
          ).toFixed(2);
          order.total_value_usd = (order.total_value / order.rate).toFixed(2);
          order.invoiced_value_favorite = (
            (order.invoiced_value / order.rate) *
            +this.preferredRate.rate
          ).toFixed(2);
          order.total_value_favorite = (
            (order.total_value / order.rate) *
            +this.preferredRate.rate
          ).toFixed(2);
        }
      });
    }
    this.updatedOrders = response.data.updatedOrders;
  }
  @action.bound fetchUpdatedOrdersError(error) {
    console.log(error);
  }
  @action fetchAvailableRates() {
    return Axios.get(
      `${process.env.REACT_APP_API}/rates/availableRates`,
      this.rootStore.config()
    ).then(this.fetchAvailableRatesSuccess, this.fetchAvailableRatesError);
  }
  @action.bound fetchAvailableRatesSuccess(response) {
    this.availableRates = currencyMapper(response.data.availableRates);
    this.fetchPreferredRate();
  }
  @action.bound fetchAvailableRatesError(error) {
    console.log(error);
  }
  @action generatePrint(id) {
    return Axios.get(
      `${process.env.REACT_APP_API}/orders/print?id=${id}`,
      this.rootStore.config()
    ).then(this.generatePrintSuccess, this.generatePrintError);
  }
  @action.bound generatePrintSuccess(response) {
    const win = window.open('about:blank');
    win.document.write(response.data);
  }
  @action.bound generatePrintError(error) {
    console.log(error);
  }
  @action fetchPreferredRate() {
    return Axios.get(
      `${process.env.REACT_APP_API}/rates/preferredRate?organisation=${this.rootStore.currentUserOrg.id}`,
      this.rootStore.config()
    ).then(this.fetchPreferredRateSuccess, this.fetchPreferredRateError);
  }
  @action.bound fetchPreferredRateSuccess(response) {
    if (
      this.preferredRate.name === 'USD' &&
      response.data.rate.rate !== 'USD'
    ) {
      const param = this.rootStore.currentUserOrg.is_a_supplier
        ? this.rootStore.currentUserOrg.name
        : this.rootStore.currentUserOrg.p_id;
      this.rootStore.ordersStore.fetchUpdatedOrders(param);
    }
    this.preferredRate = response.data.rate;
  }
  @action.bound fetchPreferredRateError(error) {
    console.log(error);
  }
  @action setPreferredCurrency(name) {
    this.rootStore.uiStateStore.spinner = true;
    return Axios.post(
      `${process.env.REACT_APP_API}/orders/updated`,
      { rate: name },
      this.rootStore.config()
    ).then(this.setPreferredCurrencySuccess, this.setPreferredCurrencyError);
  }
  @action.bound setPreferredCurrencySuccess(response) {
    this.rootStore.uiStateStore.spinner = false;
    this.fetchPreferredRate();
  }
  @action.bound setPreferredCurrencyError(error) {
    this.rootStore.uiStateStore.spinner = false;
    console.log(error);
  }
}

export default OrdersStore;
