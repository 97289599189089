import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from './components/login/Login';
import { LastLocationProvider } from 'react-router-last-location';
import Main from './components/main/Main';
import { inject, observer } from 'mobx-react';
import PrivateRoute from './components/privateRoute/PrivateRoute';
import NotFound from './components/not-found/NotFound';

const App = inject('rootStore')(
  observer(({ rootStore }) => {
    return (
      <BrowserRouter>
        <LastLocationProvider>
          <Switch>
            <Route exact path="/login" component={Login} />
            <PrivateRoute
              isAuthenticated={rootStore.isAuthenticated}
              currentUser={rootStore.currentUser}
              org={rootStore.currentUserOrg}
              path="/home"
              component={Main}
            />
            <PrivateRoute
              isAuthenticated={rootStore.isAuthenticated}
              currentUser={rootStore.currentUser}
              org={rootStore.currentUserOrg}
              path="/"
              component={NotFound}
            />
          </Switch>
        </LastLocationProvider>
      </BrowserRouter>
    );
  })
);
export default App;
