import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as styles from './Login.css';
@withRouter
@inject('rootStore')
@observer
class Login extends React.PureComponent {
  componentWillUnmount() {
    this.props.rootStore.authStore.reset();
  }

  handleEmailChange = (e) =>
    this.props.rootStore.authStore.setEmail(e.target.value);

  handlePasswordChange = (e) =>
    this.props.rootStore.authStore.setPassword(e.target.value);

  handleSubmitForm = (e) => {
    e.preventDefault();
    this.props.rootStore.authStore
      .login()
      .then((response) => {
        this.props.rootStore.currentUser = response.data.user;
        this.props.rootStore.setToken(response.data.token);
        this.props.rootStore.getCurrentUserOrg();
        this.props.history.replace('/home/welcome');
      })
      .catch((error, msg) => {
        this.props.rootStore.uiStateStore.showPopup = true;
        this.props.rootStore.uiStateStore.popupMessage = error.message;
      });
  };

  render() {
    const popup = css`
      display: ${this.props.rootStore.uiStateStore.showPopup
        ? 'block'
        : 'none'};
      text-align: center;
      background-color: #f4b400;
      border-color: #f4b400;
      color: #fff;
      bottom: 0px;
      position: absolute;
      width: 100%;
    `;

    return (
      <div css={styles.mainDiv}>
        <Grid container css={styles.mainGrid}>
          <Hidden only={['sm']}>
            <Grid item lg={2} md={2} sm={2}></Grid>
          </Hidden>
          <Hidden only={['sm', 'xl', 'lg', 'md']}>
            <Grid item xs={2}></Grid>
          </Hidden>
          <Grid css={styles.imgGrid} item lg={5} md={4} sm={6} xs={12}>
            <div css={styles.img}></div>
          </Grid>
          <Hidden only={['sm', 'xl', 'lg', 'md']}>
            <Grid item xs={2}></Grid>
          </Hidden>
          <Grid css={styles.imgGrid} item lg={4} md={4} sm={6} xs={8}>
            <div css={styles.socialMedia}>
              <div>
                <a
                  target="_blank"
                  href="https://twitter.com/PurchasePlus"
                  rel="noopener noreferrer"
                >
                  <span className="fa-stack fa-lg">
                    <i className="fa fa-circle fa-stack-2x"></i>
                    <i className="fa fa-twitter fa-stack-1x fa-inverse"></i>
                  </span>
                </a>
              </div>
              <div>
                <a
                  target="_blank"
                  href="https://www.facebook.com/Marketboomer/"
                  rel="noopener noreferrer"
                >
                  <span className="fa-stack fa-lg">
                    <i className="fa fa-circle fa-stack-2x"></i>
                    <i className="fa fa-facebook fa-stack-1x fa-inverse"></i>
                  </span>
                </a>
              </div>
              <div>
                <a
                  target="_blank"
                  href="https://www.youtube.com/channel/UCOkR8NE-wlgVl5klXjZi09Q/featured"
                  rel="noopener noreferrer"
                >
                  <span className="fa-stack fa-lg">
                    <i className="fa fa-circle fa-stack-2x"></i>
                    <i className="fa fa-youtube-play fa-stack-1x fa-inverse"></i>
                  </span>
                </a>
              </div>
              <div>
                <a
                  target="_blank"
                  href="http://www.linkedin.com/groups/PurchasePlus-4355779?trk=myg_ugrp_ovr"
                  rel="noopener noreferrer"
                >
                  <span className="fa-stack fa-lg">
                    <i className="fa fa-circle fa-stack-2x"></i>
                    <i className="fa fa-linkedin fa-stack-1x fa-inverse"></i>
                  </span>
                </a>
              </div>
            </div>
          </Grid>
          <Hidden only={['sm', 'xs']}>
            <Grid item lg={1} md={2} sm={2}></Grid>
          </Hidden>
          <Grid item lg={2} md={2} sm={2}></Grid>
          <Hidden only={['xs']}>
            <Grid item lg={5} md={7} sm={12} css={styles.textCell}>
              <h2 css={styles.loginHeader}>Powerful purchasing made simple.</h2>
              <p css={styles.textBlock}>
                Buy the right products from the right supplier at the right
                price.
              </p>
              <p css={styles.textBlock}>
                Connect to all your suppliers online and find new ones. Find the
                product you are looking for. See available prices and pick the
                one that suits your budget. Make sure your whole team gets it
                right every time. See what you buy, who you buy it from and what
                it costs you at any time.
              </p>
              <p css={styles.textBlock}>
                To be one of the first to use this powerful new tool for your
                business email us at invite@marketboomer.com.
              </p>
            </Grid>
          </Hidden>
          <Hidden lgUp>
            <Grid item lg={2} md={2} sm={2}></Grid>
            <Grid item lg={2} md={2} sm={2}></Grid>
          </Hidden>
          <Hidden only={['sm', 'xl', 'lg', 'md']}>
            <Grid item xs={2}></Grid>
            <Grid item xs={2}></Grid>
          </Hidden>
          <Grid item lg={4} md={8} sm={12} xs={12} container direction="column">
            <form css={styles.form}>
              <div>
                <div css={styles.divInput}>
                  <input
                    id="email"
                    onChange={this.handleEmailChange}
                    placeholder="Email"
                  ></input>
                </div>
                <div css={styles.divInput}>
                  <input
                    id="password"
                    type="password"
                    onChange={this.handlePasswordChange}
                    placeholder="Password"
                  ></input>
                </div>
              </div>
              <div css={styles.submitDiv}>
                <button onClick={this.handleSubmitForm} className="submit">
                  Sign In
                </button>
              </div>
            </form>
          </Grid>
        </Grid>

        <div>
          <div css={styles.copyright}>
            Version 124 Copyright © 1997-2019 Marketboomer Pty Ltd
          </div>
          <div css={popup}>
            {this.props.rootStore.uiStateStore.popupMessage ===
            'Please enter a valid email address'
              ? 'Please enter a valid email address'
              : this.props.rootStore.uiStateStore.popupMessage
              ? 'Invalid email or password.'
              : null}
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
