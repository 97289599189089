import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';

/** @jsx jsx */
import * as styles from './Main.css';
import { jsx } from '@emotion/core';
import MainWidget from './MainWidget';
import Rates from '../rates/Rates';
import Suppliers from '../suppliers/Suppliers';
import HotelItemMatching from '../hotelItemMatching/HotelItemMatching';
import PrivateRoute from '../privateRoute/PrivateRoute';
import OrderPage from '../orders/OrderPage';
import Invoice from '../invoice/Invoice';
import SingleInvoice from '../invoice/SingleInvoice';
import { Button } from '@material-ui/core';
import { reaction } from 'mobx';
import CatalogueProduct from '../suppliers/CatalugueProduct';
import SingleOrder from '../orders/SingleOrder';
import SideBar from './SideBar';
import ToolBar from './ToolBar';
@withRouter
@inject('rootStore')
@observer
class Main extends React.PureComponent {
  constructor(props) {
    super(props);
    this.changeRole = this.changeRole.bind(this);
  }

  componentDidMount() {
    if (this.props.rootStore.currentUserOrg.name) {
      this.props.rootStore.ordersStore.fetchAvailableRates();
    }
    reaction(
      () => this.props.rootStore.currentUserOrg,
      () => {
        this.props.rootStore.ordersStore.fetchAvailableRates();
      }
    );
  }

  changeRole() {
    this.props.rootStore.authStore.changeRole();
  }

  render() {
    return (
      <div css={styles.mainDiv}>
        <ToolBar></ToolBar>
        <SideBar></SideBar>
        <div
          css={styles.pageContent}
          className={
            this.props.rootStore.uiStateStore.isMenuTextVisible ? '' : 'hidden'
          }
        >
          <div css={styles.tabContent}>
            <div css={styles.widget}>
              <Switch>
                <Route
                  exact
                  path="/home/welcome"
                  component={MainWidget}
                ></Route>
                <PrivateRoute
                  mustBePurchaser={true}
                  isAuthenticated={this.props.rootStore.isAuthenticated}
                  currentUser={this.props.rootStore.currentUser}
                  org={this.props.rootStore.currentUserOrg}
                  exact
                  path="/home/rates"
                  component={Rates}
                ></PrivateRoute>
                <Route path="/home/suppliers">
                  <Suppliers></Suppliers>
                </Route>
                <Route path="/home/orders">
                  <OrderPage></OrderPage>
                </Route>
                <Route path="/home/invoices">
                  <Invoice></Invoice>
                </Route>
                <Route path="/home/:id/invoice">
                  <SingleInvoice></SingleInvoice>
                </Route>
                <Route path="/home/:id/order">
                  <SingleOrder></SingleOrder>
                </Route>
                <Route path="/home/:id/catalogue">
                  <CatalogueProduct></CatalogueProduct>
                </Route>
                <Route path="/home/matching">
                  <HotelItemMatching></HotelItemMatching>
                </Route>
              </Switch>

              {this.props.rootStore.currentUser.email ===
              'varinee.chimpad@marketboomer.com' ? (
                <Button
                  variant="contained"
                  color="primary"
                  css={styles.exportAllButton}
                  onClick={() => this.changeRole()}
                >
                  Change Role
                </Button>
              ) : null}
            </div>
          </div>
        </div>
        <div css={styles.footer}></div>
      </div>
    );
  }
}

export default Main;
