import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as styles from './Invoice.css';
import { formatDate } from '../../helpers/formatDate';
import { Button, TextField, Tooltip } from '@material-ui/core';
import Lines from './Lines';

@withRouter
@inject('rootStore')
@observer
class SingleInvoice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      taxError: false,
      shippingError: false,
    };
  }
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.props.rootStore.invoicesStore.setCurrentInvoice(params.id);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }
  onSave() {
    if (
      +this.props.rootStore.invoicesStore.currentInvoice.shipping === 0 ||
      +this.props.rootStore.invoicesStore.currentInvoice.tax === 0
    ) {
      const errors = {
        taxError: false,
        shippingError: false,
      };

      if (+this.props.rootStore.invoicesStore.currentInvoice.shipping === 0) {
        errors.shippingError = true;
      }
      if (+this.props.rootStore.invoicesStore.currentInvoice.tax === 0) {
        errors.taxError = true;
      }
      this.setState(errors);
      return;
    }
    this.props.rootStore.invoicesStore.saveCurrentInvoice();
    this.setState({
      taxError: false,
      shippingError: false,
    });
  }
  onCancel() {
    const {
      match: { params },
    } = this.props;
    this.props.rootStore.invoicesStore.setCurrentInvoice(params.id);
  }
  render() {
    return (
      <div css={styles.mainDiv} className="small">
        <h1>{this.props.rootStore.invoicesStore.currentInvoice.number}</h1>
        {this.props.rootStore.invoicesStore.currentInvoice.number ? (
          <div css={styles.flexBody}>
            <div css={styles.flexColumn}>
              <div css={styles.columnItem}>
                <div>Invoice Number:</div>
                <p>
                  <b>
                    {this.props.rootStore.invoicesStore.currentInvoice.number}
                  </b>
                </p>
              </div>
              <div css={styles.columnItem}>
                <div>Invoice Date:</div>
                <p>
                  <b>
                    {formatDate(
                      this.props.rootStore.invoicesStore.currentInvoice
                        .invoice_date
                    )}
                  </b>
                </p>
              </div>
              <div css={styles.columnItem}>
                <div>Purchase Order:</div>
                <p>
                  <b>
                    {this.props.rootStore.invoicesStore.currentInvoice.order}
                  </b>
                </p>
              </div>
              <div css={styles.columnItem}>
                <div>State:</div>
                <p>
                  <b>
                    {this.props.rootStore.invoicesStore.currentInvoice.state}
                  </b>
                </p>
              </div>
              <div css={styles.columnItem}>
                <div>Delivery Type:</div>

                {this.props.rootStore.invoicesStore.currentInvoice.state ===
                'open' ? (
                  <textarea
                    value={
                      this.props.rootStore.invoicesStore.currentInvoice
                        .delivery_type
                    }
                    onChange={(el) => {
                      if (
                        this.props.rootStore.invoicesStore.currentInvoice
                          .delivery_type &&
                        this.props.rootStore.invoicesStore.currentInvoice
                          .delivery_type.length < 256
                      ) {
                        this.props.rootStore.invoicesStore.currentInvoice.delivery_type =
                          el.target.value;
                      }
                    }}
                  ></textarea>
                ) : (
                  <p>
                    <b>
                      {
                        this.props.rootStore.invoicesStore.currentInvoice
                          .delivery_type
                      }
                    </b>
                  </p>
                )}
              </div>
            </div>
            <div css={styles.flexColumn}>
              <div css={styles.columnItem}>
                <div>Supplier:</div>
                <p>
                  <b>
                    {this.props.rootStore.invoicesStore.currentInvoice.supplier}
                  </b>
                </p>
              </div>
              <div css={styles.columnItem}>
                <div>Currency:</div>
                <p>
                  <b>
                    {this.props.rootStore.invoicesStore.currentInvoice.currency}
                  </b>
                </p>
              </div>
              <div css={styles.columnItem}>
                <div>Purchaser:</div>
                <p>
                  <b>
                    {
                      this.props.rootStore.invoicesStore.currentInvoice
                        .purchaser
                    }
                  </b>
                </p>
              </div>
              <div css={styles.columnItem}>
                <div>Delivery Terms:</div>
                {this.props.rootStore.invoicesStore.currentInvoice.state ===
                'open' ? (
                  <textarea
                    value={
                      this.props.rootStore.invoicesStore.currentInvoice
                        .delivery_terms
                    }
                    onChange={(el) => {
                      if (
                        this.props.rootStore.invoicesStore.currentInvoice
                          .delivery_terms &&
                        this.props.rootStore.invoicesStore.currentInvoice
                          .delivery_terms.length < 256
                      ) {
                        this.props.rootStore.invoicesStore.currentInvoice.delivery_terms =
                          el.target.value;
                      }
                    }}
                  ></textarea>
                ) : (
                  <p>
                    <b>
                      {
                        this.props.rootStore.invoicesStore.currentInvoice
                          .delivery_terms
                      }
                    </b>
                  </p>
                )}
              </div>
            </div>
            <div css={styles.flexColumn}>
              <div css={styles.columnItem}>
                <div css={styles.margin}>
                  Freight Cost (
                  {this.props.rootStore.invoicesStore.currentInvoice.currency})
                  <b> *</b>:
                </div>
                {this.props.rootStore.invoicesStore.currentInvoice.state ===
                'open' ? (
                  <TextField
                    label={this.state.shippingError ? 'Mandatory field' : ''}
                    error={this.state.shippingError}
                    placeholder="0.00"
                    id="shipping"
                    inputProps={{ min: '0.00' }}
                    value={
                      this.props.rootStore.invoicesStore.currentInvoice
                        .shipping_amount === 0
                        ? '0.00'
                        : this.props.rootStore.invoicesStore.currentInvoice
                            .shipping_amount
                    }
                    onChange={(event) => {
                      if (event.target.value > -1) {
                        this.props.rootStore.invoicesStore.currentInvoice.shipping_amount =
                          event.target.value;
                        this.props.rootStore.invoicesStore.currentInvoice.shipping_tax_amount =
                          parseFloat(event.target.value) *
                          this.props.rootStore.invoicesStore.currentInvoice
                            .shipping_tax_percentage *
                          0.01;
                        this.props.rootStore.invoicesStore.currentInvoice.total_freight =
                          +this.props.rootStore.invoicesStore.currentInvoice
                            .shipping_amount;
                        this.props.rootStore.invoicesStore.recalculateLines();
                      } else if (event.target.value < 0) {
                        event.target.value = 0;
                        this.props.rootStore.invoicesStore.currentInvoice.shipping_amount =
                          event.target.value;
                        this.props.rootStore.invoicesStore.currentInvoice.shipping_tax_amount =
                          parseFloat(event.target.value) *
                          this.props.rootStore.invoicesStore.currentInvoice
                            .shipping_tax_percentage *
                          0.01;
                        this.props.rootStore.invoicesStore.currentInvoice.total_freight =
                          +this.props.rootStore.invoicesStore.currentInvoice
                            .shipping_amount;
                        this.props.rootStore.invoicesStore.recalculateLines();
                      }
                    }}
                    type="number"
                    margin="normal"
                  />
                ) : (
                  <p>
                    <b>
                      {this.props.rootStore.invoicesStore.currentInvoice
                        .shipping_amount
                        ? this.props.rootStore.invoicesStore.currentInvoice.shipping_amount.toFixed(
                            2
                          )
                        : 0}
                    </b>
                  </p>
                )}
              </div>
              <div css={styles.columnItem}>
                <div css={styles.margin}>
                  Handling Cost (
                  {this.props.rootStore.invoicesStore.currentInvoice.currency})
                  <b> *</b>:
                </div>
                {this.props.rootStore.invoicesStore.currentInvoice.state ===
                'open' ? (
                  <TextField
                    label={this.state.shippingError ? 'Mandatory field' : ''}
                    error={this.state.shippingError}
                    placeholder="0.00"
                    id="handling"
                    inputProps={{ min: '0.00' }}
                    value={
                      this.props.rootStore.invoicesStore.currentInvoice
                        .total_handling === 0
                        ? '0.00'
                        : this.props.rootStore.invoicesStore.currentInvoice
                            .total_handling
                    }
                    onChange={(event) => {
                  
                      if (event.target.value > -1) {
                        this.props.rootStore.invoicesStore.currentInvoice.total_handling =
                          event.target.value;
                       
                       this.props.rootStore.invoicesStore.recalculateLines_handling();
                      } else if (event.target.value < 0) {
                        event.target.value = 0;
                        this.props.rootStore.invoicesStore.currentInvoice.shipping_amount =
                          event.target.value;
                        this.props.rootStore.invoicesStore.currentInvoice.shipping_tax_amount =
                          parseFloat(event.target.value) *
                          this.props.rootStore.invoicesStore.currentInvoice
                            .shipping_tax_percentage *
                          0.01;
                        this.props.rootStore.invoicesStore.currentInvoice.total_handling =
                          +this.props.rootStore.invoicesStore.currentInvoice
                            .shipping_amount;
                        this.props.rootStore.invoicesStore.recalculateLines();
                      }
                    }}
                    type="number"
                    margin="normal"
                  />
                ) : (
                  <p>
                    <b>
                      {this.props.rootStore.invoicesStore.currentInvoice
                        .total_handling
                        ? this.props.rootStore.invoicesStore.currentInvoice.total_handling.toFixed(
                            2
                          )
                        : 0}
                    </b>
                  </p>
                )}
              </div>
              <div css={styles.columnItem}>
                <div>Invoice Total Value:</div>
                <p>
                  <b>
                    {this.props.rootStore.invoicesStore.currentInvoice.net_amount.toFixed(
                      2
                    )}
                  </b>
                </p>
              </div>
              <div css={styles.columnItem}>
                <div css={styles.margin}>Custom Duty Amout:</div>
                <p>
                  <b>
                    {parseFloat(
                      this.props.rootStore.invoicesStore.currentInvoice.cda
                    ).toFixed(2)}
                  </b>
                </p>
              </div>
              <div css={styles.columnItem}>
                <div>Invoice GST/VAT:</div>
                <p>
                  <b>
                    {parseFloat(
                      this.props.rootStore.invoicesStore.currentInvoice.vat
                    ).toFixed(2)}
                  </b>
                </p>
              </div>
              <div css={styles.columnItem}>
                <div>
                  Total weight, Kg{' '}
                  <Tooltip title="Weight for all items , weight * quantity">
                    <i
                      className="fa fa-warning"
                      style={{ fontSize: '18px', color: 'blue' }}
                    ></i>
                  </Tooltip>{' '}
                  :{'  '}
                </div>
                <p>
                  <b>
                    {this.props.rootStore.invoicesStore.currentInvoice.weight
                      ? this.props.rootStore.invoicesStore.currentInvoice.weight.toFixed(
                          3
                        )
                      : 0}
                  </b>
                </p>
              </div>
            </div>
          </div>
        ) : null}
        <div css={styles.totalBar}>
          <p>
            <b>Calculated Totals</b>
          </p>
          <p>
            Tax Total:{' '}
            <b>
              {(
                +parseFloat(
                  this.props.rootStore.invoicesStore.currentInvoice.vat
                ) +
                +parseFloat(
                  this.props.rootStore.invoicesStore.currentInvoice.cda
                )
              ).toFixed(2) +
                ' ' +
                this.props.rootStore.invoicesStore.currentInvoice.currency}
            </b>
          </p>
          <p>
            Invoice Total(Including Tax):{'  '}
            <b>
              {(
                +this.props.rootStore.invoicesStore.currentInvoice.net_amount +
                +parseFloat(
                  this.props.rootStore.invoicesStore.currentInvoice.vat
                ) +
                +parseFloat(
                  this.props.rootStore.invoicesStore.currentInvoice.cda
                )
              ).toFixed(2)}
            </b>
          </p>
        </div>

        <div css={styles.buttons}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.onSave()}
          >
            Save
          </Button>
          {this.props.rootStore.uiStateStore.showSubmit ||
          (this.props.rootStore.invoicesStore.currentInvoice.shipping &&
            this.props.rootStore.invoicesStore.currentInvoice.tax &&
            this.props.rootStore.invoicesStore.currentInvoice.state !==
              'New') ? (
            <Button variant="contained">Submit</Button>
          ) : null}
          <Button variant="contained" onClick={() => this.onCancel()}>
            Cancel
          </Button>
        </div>
        <Lines></Lines>
      </div>
    );
  }
}

export default SingleInvoice;
