import { css } from '@emotion/core';

const form = css`
  margin-top: 56px;
  margin-left: 20px !important;
  margin-right: 20px !important;
`;
const mainDiv = css`
  padding: 20px 0;
  @media (min-width: 1280px) {
    margin-top: 128px;
  }
  width: 100%;
`;
const mainGrid = css`
  width: 100%;
`;
const textBlock = css`
  font-size: 14px;
`;
const loginHeader = css`
  font-size: 24px;
`;
const img = css`
  padding-left: 15px;
  width: 300px;
  margin: auto;
  height: 80px;
  background: url(https://d3szvx4ci3puhy.cloudfront.net/assets/katana/PurchasePlus-RGB-H-342639c33611fca5d7829b0433052cf628508758dbf70b179c8c88de8966844a.png)
    no-repeat center;
  margin-bottom: 2rem;
  @media (min-width: 992px) {
    margin-top: 128px;
  }
  @media (min-width: 768px) {
    margin-top: 0px;
    margin-left: 40px;
    margin-right: 20px;
  }
`;

const socialMedia = css`
  display: flex;
  justify-content: center;
  border-bottom: 2px solid grey;
  margin-left: 40px;
  margin-right: 20px;
  @media (min-width: 768px) {
    margin-top: 20px;
    justify-content: flex-end;
    border-bottom: none;
    margin-left: 0px;
    margin-right: 0px;
  }
  div {
    display: inline-block;
    padding: 5px;
    width: 40px;
    border: none;
    height: 40px;
    a {
      color: #337ab7;
    }
  }
`;
const textCell = css`
  padding-right: 20px;
  h2 {
    font-weight: 300 !important;
    font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
  p {
    margin-bottom: 5px;
    line-height: 22px;
    margin: 0 0 10px;
  }
  @media (min-width: 576px) {
    border-bottom: 2px solid grey;
    margin-left: 40px !important;
    margin-right: 20px !important;
  }
  @media (min-width: 1280px) {
    border-bottom: none;
    border-right: 2px solid grey;
  }
`;
const divInput = css`
  margin-bottom: 15px;
  input {
    @media (min-width: 576px) {
      margin-left: 10px;
    }
    height: auto !important;
    border-radius: 0px;
    display: block;
    width: 95%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.428571429;
    color: #555555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    padding-right: 0px;
    margin-right: 0px;
  }
`;
const copyright = css`
  color: #9c9c9c;
  text-align: right;
  padding-right: 10px;
  padding-bottom: 10px;
  position: absolute;
  bottom: 0;
  right: 0;
  margin-bottom: 10px;
`;
const submitDiv = css`
  margin-left: 20px;
  float: right;
  button {
    height: 40px;
    width: 69px;
    background-color: #53a93f;
    color: #fff;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
    transition: all 0.15s ease;
    border-radius: 2px;
    border: none;
    cursor: pointer;
  }
`;
const imgGrid = css`
  @media (min-width: 577px) and (max-width: 767px) {
    flex-basis: 100% !important;
    max-width: 100% !important;
  }
`;

export {
  form,
  imgGrid,
  submitDiv,
  copyright,
  divInput,
  textCell,
  mainDiv,
  mainGrid,
  img,
  socialMedia,
  textBlock,
  loginHeader,
};
