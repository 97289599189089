import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { inject, observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';

import * as styles from './Suppliers.css';

const itemMatchingBackendUrl =
  process.env.REACT_APP_HOTEL_ITEM_MATCHING_BACKEND;

// const boolCell = (el) => {
//   return el.value ? (
//     <i className="fa fa-check"></i>
//   ) : (
//     <i className="fa fa-times"></i>
//   );
// };

// const BoolCellProvider = (props) => (
//   <DataTypeProvider formatterComponent={boolCell} {...props} />
// );

// const EditCellProvider = (props) => (
//   <DataTypeProvider formatterComponent={editCell} {...props} />
// );

// const CustomizedHeader = ({ ...restProps }) => (
//   <Table.Row {...restProps} css={styles.tableHeader} />
// );

// const CustomizedTable = ({ ...restProps }) => (
//   <Table.Table {...restProps} css={styles.tableRows} />
// );

// const CustomizedPanel = ({ ...restProps }) => (
//   <PagingPanel.Container {...restProps} css={styles.tablePanel} />
// );

// const editCell = () => <i css={supCell} className="fa fa-edit"></i>;

@withRouter
@inject('rootStore')
@observer
class ProcessRunningIndicator extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      alert: '',
      availableFiles: [],
      isFilePicked: false,
      isLoading: false,
      requestProblem: '',
      requestRunning: '',
      requestSuccessful: '',
      selectedFile: undefined,
    };
  }

  componentDidMount = () => {
    let toUpdate = {
      alert: 'request problem',
      requestProblem: true,
    };
    const lastJobStatus = localStorage.getItem('lastJobStatus');

    fetch(`${itemMatchingBackendUrl}requestRunning`, {
      method: 'GET',
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          toUpdate = {
            requestRunning: data.is_request_running,
            requestSuccessful: !data.did_request_fail,
            requestProblem: false,
          };

          switch (true) {
            case data.is_request_running:
              toUpdate.alert = 'running';
              break;
            case data.did_request_fail:
              toUpdate.alert = 'failed';
              break;
            default:
              if (lastJobStatus === 'running' || lastJobStatus === 'undefined' || lastJobStatus === undefined || lastJobStatus === null) {
                toUpdate.alert = 'finished';
              }
          }
        }
      })
      .catch(() => {
        // `handled fetch exception: ${error.message}`
      })
      .finally(() => {
        this.setState(toUpdate);
        if (toUpdate.alert !== undefined && toUpdate.alert !== null) {
          localStorage.setItem('lastJobStatus', toUpdate.alert);
        }
      });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    this.setState({ alert: '' });
  };

  render() {
    const alert = this.state.alert;
    let alertSeverity;

    if (alert) {
      switch (true) {
        case alert === 'finished':
          alertSeverity = 'success';
          break;
        case alert === 'running':
          alertSeverity = 'info';
          break;
        default:
          alertSeverity = 'warning';
      }
    }

    const changeHandler = (event) => {
      this.setState({
        selectedFile: event.target.files[0],
        isFilePicked: true,
      });
    };

    const renderErrorRetrievingResult = () => {
      return (
        <tbody>
          <tr>
            <td>Unknown</td>
            <td>Unknown</td>
            <td>An error occurred retrieving the Job Status</td>
          </tr>
        </tbody>
      );
    };

    const renderNormalResult = (isRunning, wasSuccessful) => {
      const isRunningText = isRunning ? 'Yes' : 'No';
      let wasSuccessfulText = 'Unknown';

      if (!isRunning) {
        wasSuccessfulText = wasSuccessful ? 'Yes' : 'No';
      }

      return (
        <tbody>
          <tr>
            <td>{isRunningText}</td>
            <td>{wasSuccessfulText}</td>
            <td>No error</td>
          </tr>
        </tbody>
      );
    };

    const renderRow = (isRunning, wasSuccessful, errorRetrieving) => {
      if (errorRetrieving) {
        return renderErrorRetrievingResult();
      } else {
        return renderNormalResult(isRunning, wasSuccessful);
      }
    };

    return (
      <div css={styles.tableDiv}>
        {this.state.isLoading ? (
          <CircularProgress size={25} />
        ) : (
          <table>
            <thead>
              <tr css={styles.mytr}>
                <th>Is a process running?</th>
                <th>Was the process successful?</th>
                <th>Process error?</th>
              </tr>
            </thead>

            {renderRow(
              this.state.requestRunning,
              this.state.requestSuccessful,
              this.state.requestProblem
            )}
          </table>
        )}

        <Snackbar
          open={!!alert}
          onClose={this.handleClose}
          autoHideDuration={6000}
        >
          <Alert
            onClose={this.handleClose}
            severity={alertSeverity}
            sx={{ width: '100%' }}
          >
            Matching {alert}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default ProcessRunningIndicator;
