import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as styles from './Invoice.css';
import { withLastLocation } from 'react-router-last-location';
import {
  TextField,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  FormControlLabel,
} from '@material-ui/core';
import InvoiceTable from './InvoiceTable';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

@withRouter
@inject('rootStore')
@observer
class InvoicePage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sentFrom: null,
      sentTo: null,
    };
    this.statuses = ['open', 'cancelled', 'flagged', 'reconciled'];
    this.handleChange = this.handleChange.bind(this);
    this.searchInput = React.createRef();
    this.search = this.search.bind(this);
  }
  componentDidUpdate() {
    if (
      this.props.lastLocation &&
      this.props.lastLocation.pathname !== '/home/invoices'
    ) {
      this.props.lastLocation.pathname = this.props.location.pathname;
      this.setState({
        sentFrom: null,
        sentTo: null,
      });
    }
  }
  search() {
    if (!this.props.rootStore.uiStateStore.spinner) {
      this.props.rootStore.invoicesStore.invoicesTablePage = 0;
      this.props.rootStore.invoicesStore.fetchInvoices(
        this.props.rootStore.currentUserOrg.name,
        this.searchInput.current.children[0].children[0].value,
        this.state.sentFrom,
        this.state.sentTo,
        null,
        true
      );
    }
  }
  clear() {
    this.searchInput.current.children[0].children[0].value = '';
    this.setState({
      sentFrom: null,
      sentTo: null,
    });
  }
  handleChange(event) {
    const index = this.props.rootStore.invoicesStore.checkedStatuses.indexOf(
      event.target.value
    );
    if (index > -1) {
      this.props.rootStore.invoicesStore.checkedStatuses =
        this.props.rootStore.invoicesStore.checkedStatuses.filter(
          (elem) => elem !== event.target.value
        );
    } else {
      this.props.rootStore.invoicesStore.checkedStatuses.push(
        event.target.value
      );
    }
    this.props.rootStore.invoicesStore.fetchInvoices(
      this.props.rootStore.currentUserOrg.name,
      this.searchInput.current.children[0].children[0].value,
      this.state.sentFrom,
      this.state.sentTo,
      null,
      true
    );
  }
  render() {
    return (
      <div css={styles.mainDiv}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <h1>Invoices</h1>
          <div css={styles.toolbar}>
            <TextField
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  this.search();
                }
              }}
              disabled={this.props.rootStore.uiStateStore.spinner}
              css={styles.textfield}
              placeholder={
                this.props.rootStore.currentUserOrg.is_a_supplier
                  ? 'Search for Purchaser or Number'
                  : 'Search for Invoicer or Number'
              }
              ref={this.searchInput}
            ></TextField>
            <DatePicker
              disabled={this.props.rootStore.uiStateStore.spinner}
              css={styles.fields}
              placeholder="From Invoice Date"
              disableToolbar
              value={this.state.sentFrom}
              onChange={(value) => {
                this.setState({
                  sentFrom: value,
                });
              }}
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
            />
            <DatePicker
              disabled={this.props.rootStore.uiStateStore.spinner}
              css={styles.fields}
              placeholder="To Invoice Date"
              disableToolbar
              value={this.state.sentTo}
              onChange={(value) => {
                this.setState({
                  sentTo: value,
                });
              }}
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
            />
            <button
              disabled={this.props.rootStore.uiStateStore.spinner}
              onClick={() => this.search()}
              css={styles.searchButton}
              name="button"
            >
              <i className="fa fa-search"></i>
            </button>
            <button
              disabled={this.props.rootStore.uiStateStore.spinner}
              onClick={() => this.clear()}
              css={styles.clearButton}
              name="button"
            >
              <i className="fa fa-trash"></i>
            </button>
            <div css={styles.sortBlock}>
              <div>Sort: </div>
              <Select
                css={styles.fields}
                className="fixed"
                value={this.props.rootStore.invoicesStore.invoicesAcsSort}
                onChange={(event) => {
                  this.props.rootStore.invoicesStore.invoicesAcsSort =
                    event.target.value;
                  this.props.rootStore.invoicesStore.fetchInvoices(
                    this.props.rootStore.currentUserOrg.name,
                    this.searchInput.current.children[0].children[0].value,
                    this.state.sentFrom,
                    this.state.sentTo,
                    null,
                    true
                  );
                }}
              >
                <MenuItem key={'asc_false'} value={false}>
                  <ListItemText primary={'Invoice Date(oldest)'} />
                </MenuItem>
                <MenuItem key={'asc_true'} value={true}>
                  <ListItemText primary={'Invoice Date(latest)'} />
                </MenuItem>
              </Select>
            </div>
          </div>
          <div css={styles.statusBlock}>
            <div>Status: </div>
            <div css={styles.checkboxes}>
              {this.statuses.map((status) => (
                <div key={status}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={status}
                        checked={
                          this.props.rootStore.invoicesStore.checkedStatuses.indexOf(
                            status
                          ) > -1
                        }
                        onClick={this.handleChange}
                      />
                    }
                    label={status}
                  />
                </div>
              ))}
            </div>
          </div>
          <InvoiceTable></InvoiceTable>
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}

export default withLastLocation(InvoicePage);
