import { observable, action } from 'mobx';
import Axios from 'axios';

class ReceiversStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }
  @observable receivers = [];
  @observable isModalOpen = false;

  @action fetchReceiver() {
    return Axios.get(
      `${process.env.REACT_APP_API}/receivers/fetch`,
      this.rootStore.config()
    ).then(this.fetchReceiverSuccess, this.fetchReceiverError);
  }
  @action.bound fetchReceiverSuccess(response) {
    if (response.data.receivers) {
      this.receivers = response.data.receivers;
    }
  }
  @action.bound fetchReceiverError(error) {
    console.log(error);
  }

  @action addReceiver(email) {
    return Axios.post(
      `${process.env.REACT_APP_API}/receivers/add?email=${email}`,
      null,
      this.rootStore.config()
    ).then(this.addReceiverSuccess, this.addReceiverError);
  }
  @action.bound addReceiverSuccess(response) {
    if (response.data.receiver) {
      this.receivers.push(response.data.receiver);
    }
  }
  @action.bound addReceiverError(error) {
    console.log(error);
  }

  @action removeReceiver(email) {
    return Axios.delete(
      `${process.env.REACT_APP_API}/receivers/remove?email=${email}`,
      this.rootStore.config()
    ).then(this.removeReceiverSuccess, this.removeReceiverError);
  }

  @action.bound removeReceiverSuccess(response) {
    console.log(response);
  }

  @action.bound removeReceiverError(error) {
    console.log(error);
  }
}

export default ReceiversStore;
