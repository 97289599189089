import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  PagingPanel,
  VirtualTable,
  TableInlineCellEditing,
} from '@devexpress/dx-react-grid-material-ui';
import {
  PagingState,
  IntegratedPaging,
  EditingState,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as styles from './Invoice.css';
import { TextField, Button } from '@material-ui/core';

const CustomizedHeader = ({ ...restProps }) => (
  <Table.Row {...restProps} css={styles.tableHeader} />
);
const CustomizedTable = ({ ...restProps }) => (
  <Table.Table {...restProps} css={styles.tableRows} />
);

const CustomizedPanel = ({ ...restProps }) => (
  <PagingPanel.Container {...restProps} css={styles.tablePanel} />
);

@withRouter
@inject('rootStore')
@observer
class Lines extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeInput: -1,
      activeName: '',
      searchString: '',
    };
    this.checker = 0;
    this.turn = 1;
    this.onSave = this.onSave.bind(this);
    this.search = this.search.bind(this);
    this.commitChanges = this.commitChanges.bind(this);
    this.editChanges = this.editChanges.bind(this);
  }
  onSave() {
    this.props.rootStore.invoicesStore.saveLines();
  }
  search() {
    this.props.rootStore.invoicesStore.fetchCurrentInvoiceLines(
      this.props.rootStore.invoicesStore.currentInvoice.number,
      this.state.searchString
    );
    this.checker = 0;
  }
  commitChanges({ changed }) {
    if (changed) {
      for (
        let ind = 0;
        ind < this.props.rootStore.invoicesStore.lines.length;
        ind += 1
      ) {
        if (changed[ind]) {
          for (const property in changed[ind]) {
            if (property === 'weight') {
              if (!changed[ind].weight) {
                return false;
              }
              const regexp = /^[0-9]+(\.[0-9]{1,3})?$/;
              if (!regexp.test(changed[ind].weight)) {
                return false;
              }
              this.props.rootStore.invoicesStore.lines[ind].weight =
                changed[ind].weight;
              this.props.rootStore.invoicesStore.recalculateLines();
              this.props.rootStore.invoicesStore.recalculateLines_handling();
            }
            if (property === 'tax_percentage_cda') {
              if (+changed[ind].tax_percentage_cda > 100) {
                changed[ind].tax_percentage_cda = 99;
              }
              if (+changed[ind].tax_percentage_cda < 0) {
                changed[ind].tax_percentage_cda = 0;
              }
              this.props.rootStore.invoicesStore.lines[ind].tax_percentage_cda =
                +changed[ind].tax_percentage_cda;
              this.props.rootStore.invoicesStore.recalculateLines();
            }
            if (property === 'tax_percentage') {
              if (+changed[ind].tax_percentage > 100) {
                changed[ind].tax_percentage = 99;
              }
              if (+changed[ind].tax_percentage < 0) {
                changed[ind].tax_percentage = 0;
              }
              this.props.rootStore.invoicesStore.lines[ind].tax_percentage =
                +changed[ind].tax_percentage;
              this.props.rootStore.invoicesStore.recalculateLines();
            }
          }
        }
      }
    }
  }
  editChanges(changed) {
    if (changed) {
      for (
        let ind = 0;
        ind < this.props.rootStore.invoicesStore.lines.length;
        ind += 1
      ) {
        if (changed[ind]) {
          for (const property in changed[ind]) {
            if (property === 'weight') {
              if (!changed[ind].weight) {
                return false;
              }
              const regexp = /^[0-9]+(\.[0-9]{1,3})?$/;
              if (!regexp.test(changed[ind].weight)) {
                return false;
              }
              this.props.rootStore.invoicesStore.lines[ind].weight =
                changed[ind].weight;
              this.props.rootStore.invoicesStore.recalculateLines();
            }
            if (property === 'tax_percentage') {
              if (+changed[ind].tax_percentage > 100) {
                changed[ind].tax_percentage = 99;
              }
              if (+changed[ind].tax_percentage < 0) {
                changed[ind].tax_percentage = 0;
              }
              this.props.rootStore.invoicesStore.lines[ind].tax_percentage =
                +changed[ind].tax_percentage;
              this.props.rootStore.invoicesStore.recalculateLines();
            }
            if (property === 'tax_percentage_cda') {
              if (+changed[ind].tax_percentage_cda > 100) {
                changed[ind].tax_percentage_cda = 99;
              }
              if (+changed[ind].tax_percentage_cda < 0) {
                changed[ind].tax_percentage_cda = 0;
              }
              this.props.rootStore.invoicesStore.lines[ind].tax_percentage_cda =
                +changed[ind].tax_percentage_cda;
              this.props.rootStore.invoicesStore.recalculateLines();
            }
          }
        }
      }
    }
  }
  render() {
    const totalCell = (el) => {
      return el.value.toFixed(2);
    };

    const TotalCellProvider = (props) => {
      return (
        <DataTypeProvider
          formatterComponent={totalCell}
          editorComponent={totalCell}
          {...props}
        />
      );
    };
    const columns = [
      { name: 'description', title: 'Description' },
      { name: 'product_item_sell_pack_name', title: 'Pack' },
      { name: 'quantity', title: 'Quantity' },
      { name: 'product_description', title: 'Product Description' },
      { name: 'weight', title: 'Unit Weight, Kg' },
      { name: 'tax_percentage', title: 'GST/VAT %' },
      { name: 'tax_percentage_cda', title: 'Custom Duty %' },
      { name: 'freight_cost', title: 'Freight Cost' },
      { name: 'handling_cost', title: 'Handling Cost' },
      { name: 'discount_total', title: 'Discount Total' },
      { name: 'line_total_sum', title: 'Line Total' },
    ];
    const columnWidths = [
      { columnName: 'description', width: 320 },
      { columnName: 'quantity', width: 120 },
      { columnName: 'product_item_sell_pack_name', width: 120 },
      { columnName: 'product_description', width: 120 },
      { columnName: 'weight', width: 120 },
      { columnName: 'line_total_sum', width: 120 },
      { columnName: 'tax_percentage', width: 120 },
      { columnName: 'tax_percentage_cda', width: 120 },
      { columnName: 'freight_cost', width: 120 },
      { columnName: 'handling_cost', width: 120},
      { columnName: 'discount_total', width: 120 },
    ];

    const CustomizedCell = (props) => {
      const { row, column, value, onValueChange, onBlur } = props;
      if (
        (column.name === 'tax_percentage' ||
          column.name === 'tax_percentage_cda') &&
        this.props.rootStore.currentUserOrg.is_a_purchaser
      ) {
        return (
          <td
            style={{ ...props.style }}
            onBlur={(event) => {
              return onBlur(event.target.value);
            }}
          >
            <input
              type="text"
              css={styles.cCell}
              id={row.id}
              placeholder="0.00"
              min="0.00"
              max="99"
              ref={this[column.name + row.id]}
              name={column.name + row.id}
              value={value}
              onBlur={(event) => {
                return onBlur(event.target.value);
              }}
              onChange={(event) => {
                return onValueChange(event.target.value);
              }}
            />
          </td>
        );
      }
      if (
        column.name === 'weight' &&
        this.props.rootStore.currentUserOrg.is_a_purchaser
      ) {
        return (
          <td
            style={{ ...props.style }}
            onBlur={(event) => {
              return onBlur(event.target.value);
            }}
          >
            <input
              type="text"
              css={styles.cCell}
              id={row.id}
              placeholder="0.00"
              ref={this[column.name + row.id]}
              name={column.name + row.id}
              value={value}
              onBlur={(event) => {
                return onBlur(event.target.value);
              }}
              onChange={(event) => {
                return onValueChange(event.target.value);
              }}
            />
          </td>
        );
      }
      const { editingEnabled, onValueChange: relete, ...rest } = props;
      return <Table.Cell {...rest} />;
    };

    this.checker = 0;
    return (
      <div css={styles.tableDiv}>
        <div>
          <TextField
            placeholder="Search for Invoice Lines"
            value={this.state.searchString}
            css={styles.textfield}
            className="lines"
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                this.search();
              }
            }}
            onChange={(el) => {
              this.setState({
                searchString: el.target.value,
              });
            }}
          ></TextField>
          <button
            css={styles.searchButton}
            className="lines"
            onClick={() => this.search()}
            name="button"
          >
            <i className="fa fa-search"></i>
          </button>
        </div>
        {this.props.rootStore.invoicesStore.lines ? (
          <Grid
            rows={
              this.props.rootStore.invoicesStore.lines
                ? this.props.rootStore.invoicesStore.lines
                : []
            }
            columns={columns}
          >
            <PagingState defaultCurrentPage={0} pageSize={30} />
            <IntegratedPaging />
            <VirtualTable tableComponent={CustomizedTable} />
            <TableColumnResizing
              defaultColumnWidths={columnWidths}
              resizingMode={'widget'}
            />
            <TableHeaderRow rowComponent={CustomizedHeader} />
            <EditingState
              onCommitChanges={this.commitChanges}
              onRowChangesChange={this.editChanges}
              columnExtensions={[
                { columnName: 'freight_cost', editingEnabled: false },
                { columnName: 'handling_cost', editingEnabled: false },
                { columnName: 'description', editingEnabled: false },
                {
                  columnName: 'quantity',
                  editingEnabled: false,
                },
                {
                  columnName: 'product_item_sell_pack_name',
                  editingEnabled: false,
                },
                { columnName: 'product_description', editingEnabled: false },
                { columnName: 'line_total_sum', editingEnabled: false },
                { columnName: 'discount_total', editingEnabled: false },
              ]}
            />
            <TotalCellProvider
              for={['line_total_sum', 'freight_cost', 'handling_cost']}
            ></TotalCellProvider>
            <TableInlineCellEditing
              cellComponent={CustomizedCell}
            ></TableInlineCellEditing>
            <PagingPanel containerComponent={CustomizedPanel} />
          </Grid>
        ) : null}
        {this.props.rootStore.currentUserOrg.is_a_purchaser ? (
          <Button
            variant="contained"
            color="primary"
            css={styles.saveLine}
            onClick={() => this.onSave()}
          >
            Save Lines
          </Button>
        ) : null}
      </div>
    );
  }
}

export default Lines;
