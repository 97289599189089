import { observable, action } from 'mobx';
import Axios from 'axios';

class SuppliersStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }
  @observable suppliers = [];
  @observable proccessedSuppliers = [];
  @observable currentSupplier = {
    supplier_name: 'none',
  };
  @observable currentSupplierCatalogues = [];
  @observable currentCatalogue = {
    title: 'none',
  };
  @observable currentCatalogueProducts = [];
  @action setCurrentSupplierCatalogue(p_id) {
    this.currentSupplierCatalogues.forEach((el) => {
      if (el.p_id === p_id) {
        this.currentSupplier = el;
      }
    });
    this.fetchCatalogueProducts(p_id);
  }
  @action setCurrentSupplier(name) {
    this.suppliers.forEach((el) => {
      if (el.supplier_name === name) {
        this.currentSupplier = el;
      }
    });
    this.fetchSupplierCatalogues();
  }

  @action fetchCatalogueProducts(id) {
    return Axios.get(
      `${process.env.REACT_APP_API}/catalogues/products?id=${id}`,
      this.rootStore.config()
    ).then(
      this.fetchCatalogueProductsSuccess,
      this.fetchCatalogueProductsError
    );
  }

  @action.bound fetchCatalogueProductsSuccess(response) {
    this.currentCatalogueProducts = response.data.products;
  }
  @action.bound fetchCatalogueProductsError(error) {
    console.log(error);
  }

  @action fetchSupplierCatalogues(name) {
    if (name) {
      return Axios.get(
        `${process.env.REACT_APP_API}/suppliers/catalogues?name=${name}`,
        this.rootStore.config()
      ).then(
        this.fetchSupplierCataloguesSuccess,
        this.fetchSupplierCataloguesError
      );
    }
    return Axios.get(
      `${process.env.REACT_APP_API}/suppliers/catalogues`,
      this.rootStore.config()
    ).then(
      this.fetchSupplierCataloguesSuccess,
      this.fetchSupplierCataloguesError
    );
  }

  @action.bound fetchSupplierCataloguesSuccess(response) {
    this.currentSupplierCatalogues = response.data.result;
  }
  @action.bound fetchSupplierCataloguesError(error) {
    console.log(error);
  }

  @action fetchSuppliers(id, name) {
    if (name) {
      return Axios.get(
        `${process.env.REACT_APP_API}/suppliers?id=${id}&name=${name}`,
        this.rootStore.config()
      ).then(this.fetchSuppliersSuccess, this.fetchSuppliersError);
    }
    return Axios.get(
      `${process.env.REACT_APP_API}/suppliers?id=${id}`,
      this.rootStore.config()
    ).then(this.fetchSuppliersSuccess, this.fetchSuppliersError);
  }
  @action.bound fetchSuppliersSuccess(response) {
    this.suppliers = response.data.suppliers;
    this.proccessedSuppliers = [];
    this.suppliers.forEach((sup) => {
      this.proccessedSuppliers.push({
        edit: 'edit',
        supplierNumber: sup.supplier_number,
        supplierBNumber: '',
        supplier: sup.supplier_name,
        preferredSupplier: '',
        enable: !!sup.is_enabled,
        isBillable: '',
        purchaserPricePreferred: '',
        discountRate: '',
        emailAddress: '',
        punchoutEnabled: '',
        defaultDepartment: '',
        defaultAccount: '',
        defaultCategory: '',
        locationCode: '',
        invoicingContacts: '',
        formatMapped: '',
        contact: '',
        terms: '',
        promote: '',
      });
    });
  }
  @action.bound fetchSuppliersError(error) {
    console.log(error);
  }
}

export default SuppliersStore;
