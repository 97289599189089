import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Modal from '@material-ui/core/Modal';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import { Button, TextField } from '@material-ui/core';

@withRouter
@inject('rootStore')
@observer
class ReceiversModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      errorText: '',
      receiverEmail: '',
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.addReceiver = this.addReceiver.bind(this);
  }

  addReceiver() {
    if (
      this.state.receiverEmail.match(
        /^\s{0,}(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s{0,}$/
      )
    ) {
      this.setState({ errorText: '' });
      this.props.rootStore.receiversStore.addReceiver(this.state.receiverEmail);
    } else {
      this.setState({ errorText: 'Invalid email format' });
    }
  }

  componentDidMount() {
    this.props.rootStore.receiversStore.fetchReceiver();
  }

  handleOpen = () => {
    this.props.rootStore.receiversStore.isModalOpen = true;
  };

  handleClose = () => {
    this.props.rootStore.receiversStore.isModalOpen = false;
  };

  render() {
    const { receivers } = this.props.rootStore.receiversStore;
    const modal = css`
      background: white;
      width: 50%;
      height: 50%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    `;
    const modalReceiverItem = css`
      margin: 20px;
      background: #f6f6f6 !important;
      display: flex;
      justify-content: space-between;
      p {
        padding-left: 20px;
      }
    `;
    const modalReceiverBlock = css`
      height: 70%;
      overflow: auto;
    `;
    const modalTitle = css`
      margin: 20px;
      font-size: 24px;
    `;
    const modalInput = css`
      margin-left: 20px !important;
      margin-right: 20px !important;
      font-size: 24px;
    `;
    const modalPanel = css`
      display: flex;
      align-items: baseline;
    `;
    return (
      <Modal
        aria-labelledby="modal-title"
        open={this.props.rootStore.receiversStore.isModalOpen}
        onClose={this.handleClose}
      >
        <div css={modal}>
          <h2 css={modalTitle} id="modal-title">
            Organisation notification list
          </h2>
          <div css={modalPanel}>
            <TextField
              css={modalInput}
              label="Add Receiver"
              error={!!this.state.errorText}
              value={this.state.receiverEmail}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  this.addReceiver();
                }
              }}
              onChange={(el) => {
                this.setState({
                  receiverEmail: el.target.value,
                });
              }}
            ></TextField>
            <Button css={modalInput} onClick={() => this.addReceiver()}>
              Add
            </Button>
          </div>
          <div css={modalReceiverBlock}>
            {receivers.map((receiver, index) => (
              <div css={modalReceiverItem} key={`receiver_${receiver.email}`}>
                <p>{receiver.email}</p>
                <Button
                  onClick={() => {
                    this.props.rootStore.receiversStore.receivers.splice(
                      index,
                      1
                    );
                    this.props.rootStore.receiversStore.removeReceiver(
                      receiver.email
                    );
                  }}
                >
                  Delete
                </Button>
              </div>
            ))}
          </div>
        </div>
      </Modal>
    );
  }
}

export default ReceiversModal;
