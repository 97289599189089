import { css } from '@emotion/core';
const flexBody = css`
  padding-left: 10px;
  height: 65%;
  background-color: #cde2ec;
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
`;
const flexColumn = css`
  width: 40%;
  display: flex;
  flex-direction: column;
  color: black;
`;
const mainDiv = css`
  h1 {
    padding-left: 5px;
  }
  height: 90%;
  &.small {
    height: 60%;
  }
`;
const buttons = css`
  position: fixed !important;
  bottom: 8px !important;
  z-index: 100 !important;
  right: 150px !important;
  button {
    width: 150px;
    margin-right: 20px;
  }
`;
const columnItem = css`
  max-height: 20%;
  div {
    display: inline-block;
    width: 40%;
    color: #404040;
    font: 300 13px/17px 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana,
      sans-serif;
  }
  input {
    background-color: #fff;
    font: 300 13px/21px 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana,
      sans-serif;
  }
  p {
    max-width: 50%;
    margin-left: 10px;
    display: inline-block;
  }
  textarea {
    padding: 5px 10px 4px;
    background-color: #fff;
    font: 300 13px/21px 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana,
      sans-serif;
    max-width: 50%;
    display: inline-block;
    resize: none;
  }
`;
const toolbar = css`
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  background: #cde2ed;
`;
const textfield = css`
  margin-top: 15px !important;
  margin-right: 5px !important;
  input {
    padding-left: 5px;
    background: white;
    font-size: 10px;
  }
  &.lines {
    margin-left: 5px !important;
    width: 80%;
  }
`;
const clearButton = css`
  background: rgb(152, 159, 162);
  border: none;
  margin-top: 15px !important;
  margin-bottom: 8px !important;
  width: 5%;
  i {
    color: white;
  }
`;
const tableDiv = css`
  background-image: linear-gradient(to bottom, #cde2ed, #f3f3f3);
  height: 65%;
  div:nth-of-type(2n) {
    max-height: 100% !important;
  }
`;
const searchButton = css`
    background: rgb(87, 181, 227);
    border: none;
    height: 25px;
    margin-top: 15px !important;
    margin-bottom: 8px !important;
    width: 10%;
    i {
        color: white;
    }
    &.lines {
        width 19%;
    }
`;
const tableRows = css`
  & tbody tr:nth-of-type(odd) {
    background: white !important;
  }
  & tbody tr:nth-of-type(even) {
    background: #f6f6f6 !important;
  }
`;

const fields = css`
  margin: 5px;
  margin-right: 5px !important;
  background: white;
  input {
    padding-left: 5px;
    font-size: 10px;
  }
`;
const orderPageMainDiv = css`
  height: 90%;
`;

const receiverButton = css`
  margin: 5px !important;
`;

const tableHeader = css`
  border-right: 1px solid #d0d0d0;
  color: #404040;
  font: 400 13px/19px 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana,
    sans-serif;
  outline: 0;
  background-color: #fff;
`;

const tablePanel = css`
  justify-content: flex-start !important;
`;

export {
  tableHeader,
  tablePanel,
  receiverButton,
  orderPageMainDiv,
  fields,
  mainDiv,
  tableRows,
  searchButton,
  tableDiv,
  flexBody,
  columnItem,
  buttons,
  toolbar,
  textfield,
  clearButton,
  flexColumn,
};
