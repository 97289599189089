import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

/** @jsx jsx */
import * as styles from './Main.css';
import { jsx } from '@emotion/core';

@withRouter
@inject('rootStore')
@observer
class ToolBar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onChangeMenuItemTextVisibility =
      this.onChangeMenuItemTextVisibility.bind(this);
    this.logout = this.logout.bind(this);
  }

  onChangeMenuItemTextVisibility() {
    this.props.rootStore.uiStateStore.isMenuTextVisible =
      !this.props.rootStore.uiStateStore.isMenuTextVisible;
    this.props.rootStore.uiStateStore.activeMenu = -1;
  }

  logout() {
    this.props.rootStore.authStore
      .logout()
      .then(() => {
        this.props.rootStore.currentUser = {};
        this.props.rootStore.clearToken();
        this.props.history.replace('/login');
      })
      .catch((error) => console.log(error));
  }

  render() {
    return (
      <div css={styles.toolBar}>
        <div css={styles.toolbarMenu}>
          <Link css={styles.img} to="/home/welcome"></Link>
          <div onClick={() => this.onChangeMenuItemTextVisibility()}>
            <i className="collapse-icon fa fa-bars"></i>
          </div>
        </div>
        <div css={styles.toolbarButton}>
          <i className="fa fa-sign-out "></i>
          <button onClick={() => this.logout()}>Sign Off</button>
        </div>
        {this.props.rootStore.currentUser &&
        this.props.rootStore.currentUser.full_name ? (
          <div css={styles.toolbarButton}>
            <i className="fa fa-user" aria-hidden="true"></i>
            <label>{this.props.rootStore.currentUser.full_name}</label>
          </div>
        ) : null}
        {this.props.rootStore.currentUserOrg &&
        this.props.rootStore.currentUserOrg.name ? (
          <div css={styles.toolbarButton}>
            <i className="fa fa-building "></i>
            <label>{this.props.rootStore.currentUserOrg.name}</label>
          </div>
        ) : null}
      </div>
    );
  }
}

export default ToolBar;
