import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  PagingPanel,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { PagingState, IntegratedPaging } from '@devexpress/dx-react-grid';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as styles from './Suppliers.css';

const CustomizedHeader = ({ ...restProps }) => (
  <Table.Row {...restProps} css={tableHeader} />
);
const CustomizedTable = ({ ...restProps }) => (
  <Table.Table {...restProps} css={tableRows} />
);

const CustomizedPanel = ({ ...restProps }) => (
  <PagingPanel.Container {...restProps} css={tablePanel} />
);

@withRouter
@inject('rootStore')
@observer
class CatalogueProduct extends React.PureComponent {
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.props.rootStore.suppliersStore.setCurrentSupplierCatalogue(params.id);
  }
  render() {
    const columns = [
      { name: 'product_code', title: 'Product Code' },
      { name: 'brand', title: 'Brand' },
      { name: 'description', title: 'Description' },
      { name: 'sell_unit_price', title: 'Sell unit' },
      { name: 'sell_unit_tax_percentage', title: 'Sell Unit Tax, %' },
    ];
    const columnWidths = [
      { columnName: 'product_code', width: 400 },
      { columnName: 'brand', width: 420 },
      { columnName: 'description', width: 120 },
      { columnName: 'sell_unit_price', width: 120 },
      { columnName: 'sell_unit_tax_percentage', width: 120 },
    ];
    return (
      <div css={styles.tableDiv}>
        <Grid
          rows={
            this.props.rootStore.suppliersStore.currentCatalogueProducts
              ? this.props.rootStore.suppliersStore.currentCatalogueProducts
              : []
          }
          columns={columns}
        >
          <PagingState defaultCurrentPage={0} pageSize={30} />
          <IntegratedPaging />
          <VirtualTable tableComponent={CustomizedTable} />
          <TableColumnResizing
            defaultColumnWidths={columnWidths}
            resizingMode={'widget'}
          />
          <TableHeaderRow rowComponent={CustomizedHeader} />
          <PagingPanel containerComponent={CustomizedPanel} />
        </Grid>
      </div>
    );
  }
}

export default CatalogueProduct;
