import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { reaction } from 'mobx';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableSelection,
  TableColumnResizing,
  PagingPanel,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  PagingState,
  CustomPaging,
  SelectionState,
  IntegratedSelection,
  DataTypeProvider,
} from '@devexpress/dx-react-grid';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as styles from './Orders.css';
import { Button } from '@material-ui/core';
import { formatDate } from '../../helpers/formatDate';

const dateCell = (el) => <p>{formatDate(el.value)}</p>;
const DateCellProvider = (props) => (
  <DataTypeProvider formatterComponent={dateCell} {...props} />
);

const CustomizedHeader = ({ ...restProps }) => (
  <Table.Row {...restProps} css={styles.tableHeader} />
);
const CustomizedTable = ({ ...restProps }) => (
  <Table.Table {...restProps} css={styles.tableRows} />
);

const CustomizedPanel = ({ ...restProps }) => (
  <PagingPanel.Container {...restProps} css={styles.tablePanel} />
);

@withRouter
@inject('rootStore')
@observer
class UpdatedOrdersTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.changeSelection = this.changeSelection.bind(this);
    this.getReport = this.getReport.bind(this);
    this.setCurrentPage = this.setCurrentPage.bind(this);
    this.prevVal = [];
  }
  setCurrentPage(value) {
    const param = this.props.rootStore.currentUserOrg.is_a_supplier
      ? this.props.rootStore.currentUserOrg.name
      : this.props.rootStore.currentUserOrg.p_id;
    this.props.rootStore.ordersStore.ordersTablePage = value;
    this.props.rootStore.ordersStore.fetchUpdatedOrders(
      param,
      null,
      null,
      null,
      null,
      null,
      value
    );
  }
  componentDidMount() {
    if (this.props.rootStore.currentUserOrg.name) {
      const param = this.props.rootStore.currentUserOrg.is_a_supplier
        ? this.props.rootStore.currentUserOrg.name
        : this.props.rootStore.currentUserOrg.p_id;
      this.props.rootStore.ordersStore.fetchUpdatedOrders(
        param,
        null,
        null,
        null,
        null,
        null,
        null,
        true
      );
    }
    reaction(
      () => this.props.rootStore.currentUserOrg,
      () => {
        const param = this.props.rootStore.currentUserOrg.is_a_supplier
          ? this.props.rootStore.currentUserOrg.name
          : this.props.rootStore.currentUserOrg.p_id;
        this.props.rootStore.ordersStore.fetchUpdatedOrders(
          param,
          null,
          null,
          null,
          null,
          null,
          null,
          true
        );
      }
    );
    reaction(
      () => this.props.rootStore.ordersStore.preferredRate,
      () => {
        this.props.rootStore.ordersStore.recalculateOrders();
      }
    );
  }
  changeSelection(value) {
    if (value && value.length > 0 && value.length > this.prevVal.length) {
      value.forEach((ind) => {
        if (!this.prevVal.includes(ind)) {
          this.props.rootStore.ordersStore.updatedIdsForReport.push(
            this.props.rootStore.ordersStore.updatedOrders[ind].id
          );
        }
      });
    } else {
      this.prevVal.forEach((ind) => {
        if (!value.includes(ind)) {
          const order = this.props.rootStore.ordersStore.updatedOrders[ind].id;
          this.props.rootStore.ordersStore.updatedIdsForReport =
            this.props.rootStore.ordersStore.updatedIdsForReport.filter(
              (elem) => elem !== order
            );
        }
      });
    }
    this.prevVal = value;
  }
  getReport(all) {
    if (this.props.rootStore.currentUserOrg.name) {
      const param = this.props.rootStore.currentUserOrg.is_a_supplier
        ? this.props.rootStore.currentUserOrg.name
        : this.props.rootStore.currentUserOrg.p_id;
      this.props.rootStore.ordersStore.getUpdatedReport(param, all);
    }
  }
  render() {
    const orderCell = (el) => {
      return (
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            this.props.rootStore.ordersStore.generatePrint(el.row.id);
          }}
        >
          Print
        </Button>
      );
    };

    const OrderCellProvider = (props) => {
      return <DataTypeProvider formatterComponent={orderCell} {...props} />;
    };
    const columns = [
      { name: 'number', title: 'Number' },
      { name: 'department', title: 'Department' },
      { name: 'supplier', title: 'Supplier' },
      { name: 'account', title: 'Account' },
      { name: 'sent_date', title: 'Sent Date' },
      { name: 'status', title: 'State' },
      { name: 'currency', title: 'Currency' },
      { name: 'total_value', title: 'Value' },
      { name: 'total_value_usd', title: 'Value(USD)' },
      { name: 'expected_delivery_date', title: 'Delivery Date' },
      { name: 'invoiced_value', title: 'Invoiced Value' },
      { name: 'invoiced_value_usd', title: 'Invoiced Value(USD)' },
      { name: 'requestor', title: 'Requestor' },
      { name: 'reference', title: 'Reference' },
      { name: 'requisition_number', title: 'Requisition Number' },
      { name: 'origin', title: 'Requsition Origin' },
      { name: 'print', title: 'Print' },
    ];
    const columnWidths = [
      { columnName: 'number', width: 120 },
      { columnName: 'department', width: 120 },
      { columnName: 'supplier', width: 120 },
      { columnName: 'account', width: 120 },
      { columnName: 'sent_date', width: 120 },
      { columnName: 'status', width: 60 },
      { columnName: 'currency', width: 60 },
      { columnName: 'total_value', width: 120 },
      { columnName: 'total_value_usd', width: 120 },
      { columnName: 'expected_delivery_date', width: 120 },
      { columnName: 'invoiced_value', width: 120 },
      { columnName: 'invoiced_value_usd', width: 120 },
      { columnName: 'requestor', width: 120 },
      { columnName: 'reference', width: 120 },
      { columnName: 'requisition_number', width: 120 },
      { columnName: 'origin', width: 120 },
      { columnName: 'print', width: 120 },
    ];
    const tableDiv = css`
      background-image: linear-gradient(to bottom, #cde2ed, #f3f3f3);
      padding: 8px 8px 0 8px;
      height: 90%;
      div:nth-of-type(2n) {
        max-height: 86% !important;
      }
      div:first-of-type {
        height: 100% !important;
      }
    `;
    const spinner = css`
      width: 90px;
      opacity: 0.5;
      position: absolute;
      height: 90px;
      left: 50%;
      top: 50%;
      * {
        width: 100%;
        height: 100%;
      }
    `;
    const exportButton = css`
      position: fixed !important;
      bottom: 8px !important;
      z-index: 100 !important;
      right: 150px !important;
    `;
    const exportAllButton = css`
      position: fixed !important;
      bottom: 8px !important;
      z-index: 100 !important;
      right: 10px !important;
    `;
    const orderLinkCell = (el) => {
      return (
        <Link
          to={`/home/${el.value}/order`}
          onClick={() =>
            this.props.rootStore.ordersStore.setCurrentOrder(el.value)
          }
        >
          {el.value}
        </Link>
      );
    };

    const OrderLinkCellProvider = (props) => {
      return <DataTypeProvider formatterComponent={orderLinkCell} {...props} />;
    };
    return (
      <div css={tableDiv}>
        <Grid
          rows={
            this.props.rootStore.ordersStore.updatedOrders
              ? this.props.rootStore.ordersStore.updatedOrders
              : []
          }
          columns={columns}
        >
          <PagingState
            defaultCurrentPage={0}
            currentPage={this.props.rootStore.ordersStore.ordersTablePage}
            pageSize={30}
            onCurrentPageChange={this.setCurrentPage}
          />
          <CustomPaging
            totalCount={this.props.rootStore.ordersStore.ordersCount}
          />
          <SelectionState
            onSelectionChange={(el) => this.changeSelection(el)}
          />
          <IntegratedSelection />
          <OrderCellProvider for={['print']}></OrderCellProvider>
          <OrderLinkCellProvider for={['number']}></OrderLinkCellProvider>
          <VirtualTable tableComponent={CustomizedTable} />
          <DateCellProvider
            for={['sent_date', 'expected_delivery_date']}
          ></DateCellProvider>
          <TableColumnResizing
            defaultColumnWidths={columnWidths}
            resizingMode={'widget'}
          />
          <TableSelection showSelectAll={true} />
          <TableHeaderRow rowComponent={CustomizedHeader} />
          <PagingPanel containerComponent={CustomizedPanel} />
        </Grid>
        {this.props.rootStore.uiStateStore.spinner && (
          <CircularProgress css={spinner} />
        )}
        {this.props.rootStore.ordersStore.updatedIdsForReport &&
        this.props.rootStore.ordersStore.updatedIdsForReport.length > 0 ? (
          <Button
            variant="contained"
            color="primary"
            css={exportButton}
            onClick={() => this.getReport()}
          >
            Export
          </Button>
        ) : null}
        <Button
          variant="contained"
          color="primary"
          css={exportAllButton}
          onClick={() => this.getReport(true)}
        >
          Export All
        </Button>
      </div>
    );
  }
}

export default UpdatedOrdersTable;
