import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { Link } from 'react-router-dom';

/** @jsx jsx */
import { jsx } from '@emotion/core';
import * as styles from './Main.css';

@withRouter
@inject('rootStore')
@observer
class SideBar extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      activeHidden: -1,
    };
    this.openSubMenu = this.openSubMenu.bind(this);
    this.displayHidden = this.displayHidden.bind(this);
  }

  openSubMenu(index) {
    const { uiStateStore } = this.props.rootStore;
    if (uiStateStore.isMenuTextVisible === true) {
      if (uiStateStore.activeMenu === index) {
        uiStateStore.activeMenu = -1;
      } else {
        uiStateStore.activeMenu = index;
      }
    }
  }

  displayHidden(index) {
    this.setState({
      activeHidden: index,
    });
  }

  render() {
    let { uiStateStore } = this.props.rootStore;
    return (
      <div
        css={styles.sideBar}
        className={uiStateStore.isMenuTextVisible ? '' : 'hidden'}
      >
        <ul css={styles.sidebarList}>
          {uiStateStore.menuLabels.map((item, index) =>
            item && item.title ? (
              <li css={styles.listItem} key={item.title}>
                <a
                  onMouseEnter={() =>
                    !uiStateStore.isMenuTextVisible && this.displayHidden(index)
                  }
                  onMouseLeave={() =>
                    !uiStateStore.isMenuTextVisible && this.displayHidden(-1)
                  }
                  className={uiStateStore.activeMenu === index ? 'active' : ''}
                  onClick={() => this.openSubMenu(index)}
                >
                  <i css={styles.listIcon} className="fa fa-file-text-o "></i>
                  <span
                    css={styles.listLabel}
                    className={
                      (uiStateStore.isMenuTextVisible ? '' : 'hidden') +
                      ' ' +
                      (this.state.activeHidden === index ? 'active' : '')
                    }
                  >
                    {item.title}
                  </span>
                  <i
                    css={styles.listIcon}
                    className={
                      'fa fa-angle-down ' +
                      (uiStateStore.activeMenu === index ? 'rotate' : 'left') +
                      ' ' +
                      (uiStateStore.isMenuTextVisible ? '' : 'hidden')
                    }
                  ></i>
                </a>
                <ul
                  css={styles.subMenu}
                  className={uiStateStore.activeMenu === index ? '' : 'none'}
                >
                  <li css={styles.subItem}>
                    {item.submenu.map((el) => (
                      <Link key={el.link} to={el.link}>
                        <span css={styles.subTitle}>{el.name}</span>
                      </Link>
                    ))}
                  </li>
                </ul>
                <div
                  onMouseEnter={() =>
                    !uiStateStore.isMenuTextVisible && this.displayHidden(index)
                  }
                  onMouseLeave={() =>
                    !uiStateStore.isMenuTextVisible && this.displayHidden(-1)
                  }
                  css={styles.hiddenMenu}
                  className={this.state.activeHidden === index ? 'active' : ''}
                >
                  {item.submenu.map((el) => (
                    <Link key={el.link} to={el.link}>
                      <span>{el.name}</span>
                    </Link>
                  ))}
                </div>
              </li>
            ) : null
          )}
        </ul>
      </div>
    );
  }
}

export default SideBar;
