import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as styles from './Rates.css';
import { TextField, Button, Select, MenuItem } from '@material-ui/core';

@withRouter
@inject('rootStore')
@observer
class Rates extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: this.formatDate(new Date().toUTCString()),
    };
    this.dates = {
      max: new Date(),
      min: new Date(new Date().setDate(new Date().getDate() - 90)),
    };
    this.setSelectedDate = this.setSelectedDate.bind(this);
    this.onSave = this.onSave.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.props.rootStore.ratesStore.fetchRates(
      this.formatDate(new Date().toUTCString())
    );
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
  setSelectedDate(event) {
    if (event.target.value) {
      this.setState({
        selectedDate: event.target.value,
      });
      this.props.rootStore.ratesStore.fetchRates(event.target.value);
    }
  }

  updateMarkup(event, index) {
    let elem = this.props.rootStore.ratesStore.rates[index];
    if (event.target.id === 'markup') {
      if (event.target.value < 100 && event.target.value > -1) {
        const sale = (+elem.rate * +event.target.value) / parseFloat(100);
        const newRate =
          (elem.previewPositive ? +Math.abs(sale) : -Math.abs(sale)) +
          +elem.rate;
        elem.previewMarkup = event.target.value;
        elem.previewRate = newRate.toFixed(6);
      }
    } else {
      const sale = (+elem.rate * elem.previewMarkup) / parseFloat(100);
      const pos = !elem.previewPositive;
      const newRate = (pos ? +Math.abs(sale) : -Math.abs(sale)) + +elem.rate;
      elem.previewPositive = pos;
      elem.previewRate = newRate.toFixed(6);
    }
  }
  onSave() {
    this.props.rootStore.ratesStore.saveRates(true).then(() => {
      this.props.rootStore.ratesStore.fetchRates(this.state.selectedDate);
    });
  }
  onCancel() {
    this.props.rootStore.ratesStore.fetchRates(this.state.selectedDate);
  }

  render() {
    return (
      <div css={styles.divMain}>
        <h1>Rates against USD</h1>
        <Paper css={styles.paper}>
          <div css={styles.tableWrapper}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="right">Currency</TableCell>
                  <TableCell align="right">Today Rate</TableCell>
                  <TableCell align="right">Markup %</TableCell>
                  <TableCell align="right">Preview Rate</TableCell>
                  <TableCell align="right">Active Rate(and markup)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody css={styles.tableRows}>
                {this.props.rootStore.ratesStore.rates.map((elem, index) => (
                  <TableRow key={`rate${index}`}>
                    <TableCell align="right">{elem.name}</TableCell>
                    <TableCell align="right">{elem.rate}</TableCell>
                    <TableCell align="right">
                      <div css={styles.flexCell}>
                        <TextField
                          css={styles.markup}
                          placeholder="0.00"
                          id="markup"
                          inputProps={{ min: '0.00', max: '99' }}
                          value={
                            elem.previewMarkup === 0
                              ? '0.00'
                              : elem.previewMarkup
                          }
                          onChange={(e) => {
                            if (e.target.value < 100 && e.target.value > -1) {
                              this.updateMarkup(e, index);
                            } else if (e.target.value > 99) {
                              e.target.value = 99;
                              this.updateMarkup(e, index);
                            } else if (e.target.value < 0) {
                              e.target.value = 0;
                              this.updateMarkup(e, index);
                            }
                          }}
                          type="number"
                          margin="normal"
                        />
                        <Select
                          css={styles.select}
                          value={elem.previewPositive ? 1 : 0}
                          color="primary"
                          id="positive"
                          onChange={(e) => this.updateMarkup(e, index)}
                        >
                          <MenuItem value={1}>Positive</MenuItem>
                          <MenuItem value={0}>Negative</MenuItem>
                        </Select>
                      </div>
                    </TableCell>
                    <TableCell align="right">{elem.previewRate}</TableCell>
                    <TableCell align="right">
                      <b>
                        {elem.proccesedRate}(
                        {elem.positive ? elem.markup : '-' + elem.markup}%)
                      </b>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
          <div css={styles.buttons}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.onSave()}
            >
              Update rates
            </Button>
            <Button variant="contained" onClick={() => this.onCancel()}>
              Cancel
            </Button>
          </div>
        </Paper>
      </div>
    );
  }
}

export default Rates;
