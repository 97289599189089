import React from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import DateFnsUtils from '@date-io/date-fns';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as styles from './Orders.css';
import { withLastLocation } from 'react-router-last-location';
import UpdatedOrdersTable from './UpdatedOrders';
import { TextField, Button } from '@material-ui/core';
import ReceiversModal from '../receivers/ReceiversModal';

@withRouter
@inject('rootStore')
@observer
class OrderPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      sentFrom: null,
      sentTo: null,
      deliveryFrom: null,
      deliveryTo: null,
    };
    this.searchInput = React.createRef();
    this.clear = this.clear.bind(this);
    this.search = this.search.bind(this);
  }
  search() {
    if (!this.props.rootStore.uiStateStore.spinner) {
      const param = this.props.rootStore.currentUserOrg.is_a_supplier
        ? this.props.rootStore.currentUserOrg.name
        : this.props.rootStore.currentUserOrg.p_id;
      this.props.rootStore.ordersStore.ordersTablePage = 0;
      this.props.rootStore.ordersStore.fetchUpdatedOrders(
        param,
        this.searchInput.current.children[0].children[0].value,
        this.state.sentFrom,
        this.state.sentTo,
        this.state.deliveryFrom,
        this.state.deliveryTo,
        null,
        true
      );
    }
  }
  clear() {
    this.searchInput.current.children[0].children[0].value = '';
    this.setState({
      sentFrom: null,
      sentTo: null,
      deliveryFrom: null,
      deliveryTo: null,
    });
  }
  render() {
    return (
      <div css={styles.mainDiv}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <h1>{`Purchaser Order's`}</h1>
          <div css={styles.toolbar}>
            <TextField
              disabled={this.props.rootStore.uiStateStore.spinner}
              css={styles.textfield}
              placeholder={
                this.props.rootStore.currentUserOrg.is_a_supplier
                  ? 'Search by Number'
                  : 'Search by Supplier or Number'
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  this.search();
                }
              }}
              ref={this.searchInput}
            ></TextField>
            <DatePicker
              disabled={this.props.rootStore.uiStateStore.spinner}
              css={styles.fields}
              placeholder="From Sent Date"
              disableToolbar
              value={this.state.sentFrom}
              onChange={(value) => {
                this.setState({
                  sentFrom: value,
                });
              }}
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
            />
            <DatePicker
              disabled={this.props.rootStore.uiStateStore.spinner}
              css={styles.fields}
              placeholder="To Sent Date"
              disableToolbar
              value={this.state.sentTo}
              onChange={(value) => {
                this.setState({
                  sentTo: value,
                });
              }}
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
            />
            <DatePicker
              disabled={this.props.rootStore.uiStateStore.spinner}
              css={styles.fields}
              placeholder="From Delivery Date"
              disableToolbar
              value={this.state.deliveryFrom}
              onChange={(value) => {
                this.setState({
                  deliveryFrom: value,
                });
              }}
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
            />
            <DatePicker
              disabled={this.props.rootStore.uiStateStore.spinner}
              css={styles.fields}
              placeholder="To Delivery Date"
              disableToolbar
              value={this.state.deliveryTo}
              onChange={(value) => {
                this.setState({
                  deliveryTo: value,
                });
              }}
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
            />
            <button
              disabled={this.props.rootStore.uiStateStore.spinner}
              onClick={() => this.search()}
              css={styles.searchButton}
              name="button"
            >
              <i className="fa fa-search"></i>
            </button>
            <button
              disabled={this.props.rootStore.uiStateStore.spinner}
              onClick={() => this.clear()}
              css={styles.clearButton}
              name="button"
            >
              <i className="fa fa-trash"></i>
            </button>
          </div>
          <Switch>
            <Route exact path="/home/orders">
              <UpdatedOrdersTable></UpdatedOrdersTable>
            </Route>
          </Switch>
          <ReceiversModal></ReceiversModal>
        </MuiPickersUtilsProvider>
      </div>
    );
  }
}

export default withLastLocation(OrderPage);
