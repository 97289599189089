import { Route, Redirect } from 'react-router-dom';
import React from 'react';

const PrivateRoute = ({
  component: Component,
  currentUser,
  isAuthenticated,
  org,
  mustBePurchaser,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      let failed = false;
      let redirectTo = '/login';
      if (isAuthenticated && mustBePurchaser && !org.is_a_purchaser) {
        redirectTo = '/home/welcome';
        failed = true;
      }
      if (props.location.pathname === '/home' && isAuthenticated) {
        return (
          <Redirect
            to={{
              pathname: '/home/welcome',
            }}
          />
        );
      }
      if (props.location.pathname === '/' && isAuthenticated) {
        return (
          <Redirect
            to={{
              pathname: '/home/welcome',
            }}
          />
        );
      }
      if (failed) {
        return (
          <Redirect
            to={{
              pathname: redirectTo,
            }}
          />
        );
      }
      return isAuthenticated ? (
        <React.Fragment>
          <Component {...props} />
        </React.Fragment>
      ) : (
        <Redirect
          to={{
            pathname: redirectTo,
          }}
        />
      );
    }}
  />
);

export default PrivateRoute;
