import React, { useState } from 'react';
import { withRouter, Route, Switch } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import * as styles from './Suppliers.css';
import UploadFileArea from './UploadFileArea';
import { withLastLocation } from 'react-router-last-location';
import ProcessRunningIndicator from './ProcessRunningIndicator';
@withRouter
@inject('rootStore')
@observer
class HotelItemMatching extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div css={styles.mainDiv}>
        <div css={styles.buttons} />
        <ProcessRunningIndicator />
        <UploadFileArea />
      </div>
    );
  }
}

export default withLastLocation(HotelItemMatching);
