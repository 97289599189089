import { css } from '@emotion/core';
const buttons = css`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  button {
    width: 150px;
    margin-right: 20px;
  }
`;
const tableWrapper = css`
  height: 90%;
  overflow: auto;
`;
const divMain = css`
  background-image: linear-gradient(to bottom, #cde2ed, #f3f3f3);
  padding: 8px 8px 0 8px;
  height: 95%;
`;
const paper = css`
  height: 90%;
`;
const markup = css`
  margin: 0 !important;
  margin-right: 5px !important;
  input {
    width: 50px;
  }
`;
const flexCell = css`
  display: flex;
  justify-content: flex-end;
`;
const tableRows = css`
  & tr:nth-of-type(odd) {
    background: white !important;
  }
  & tr:nth-of-type(even) {
    background: #f6f6f6 !important;
  }
`;
const select = css`
  div {
    width: 70px !important;
  }
`;

export {
  select,
  tableRows,
  flexCell,
  markup,
  paper,
  divMain,
  tableWrapper,
  buttons,
};
