import { observable, action } from 'mobx';
import Axios from 'axios';
import { formatDate } from '../helpers/formatDate';

class InvoicesStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @observable invoices = [];
  @observable checkedStatuses = [];
  @observable invoicesAcsSort = true;
  @observable currentInvoice = {};
  @observable invoicesTablePage = 0;
  @observable idsForReport = [];
  @observable lines = [];
  @observable invoicesCount = 0;

  @action getReport(all) {
    let query = `${process.env.REACT_APP_API}/invoice/generate?name=${this.rootStore.currentUserOrg.name}`;
    if (all) {
      const idsForReport = [];
      this.invoices.forEach((invoice) => {
        idsForReport.push(invoice.id);
      });
      idsForReport.sort();
      return Axios.post(
        query,
        { ids: idsForReport },
        { ...this.rootStore.config(), responseType: 'blob' }
      ).then(this.getReportSuccess, this.getReportError);
    }
    return Axios.post(
      query,
      { ids: this.idsForReport },
      { ...this.rootStore.config(), responseType: 'blob' }
    ).then(this.getReportSuccess, this.getReportError);
  }

  @action.bound getReportSuccess(response) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    const fileName = `Invoices_${formatDate(new Date())}.csv`;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  @action setCurrentInvoice(value) {
    let query = `${process.env.REACT_APP_API}/invoice?number=${value}`;
    return Axios.get(query, this.rootStore.config()).then(
      this.currentInvoiceSuccess,
      this.currentInvoiceError
    );
  }
  @action.bound currentInvoiceSuccess(response) {
    response.data.invoice.gross_amount_usd = +(
      response.data.invoice.gross_amount / response.data.invoice.rate
    ).toFixed(2);
    response.data.invoice.total_tax_usd = +(
      response.data.invoice.total_tax / response.data.invoice.rate
    ).toFixed(2);
    response.data.invoice.shipping_amount_usd = +(
      response.data.invoice.shipping_amount / response.data.invoice.rate
    ).toFixed(2);
    this.currentInvoice = response.data.invoice;
    this.fetchCurrentInvoiceLines(this.currentInvoice.p_id);
  }
  @action.bound currentInvoiceError(error) {
    console.log(error);
  }
  @action fetchCurrentInvoiceLines(value, search) {
    let query = `${process.env.REACT_APP_API}/invoice/lines?id=${value}`;
    if (search) {
      query += `&search=${search}`;
    }
    return Axios.get(query, this.rootStore.config()).then(
      this.fetchCurrentInvoiceSuccess,
      this.fetchCurrentInvoiceError
    );
  }
  @action.bound fetchCurrentInvoiceSuccess(response) {
    if (response.data.lines && response.data.lines.length > 0) {
      this.currentInvoice.weight = 0;
      this.currentInvoice.vat = 0;
      this.currentInvoice.cda = 0;
      this.currentInvoice.net_amount = 0;
      response.data.lines.forEach((line) => {
        this.currentInvoice.weight += +line.weight * line.quantity;
      });
      const propo =
        this.currentInvoice.total_freight / this.currentInvoice.weight;
      response.data.lines.forEach((line) => {
        line.freight_cost =
          isNaN(propo) || !isFinite(propo)
            ? 0
            : propo * line.weight * line.quantity;
        line.line_total_sum = +line.line_total + +line.freight_cost;
        this.currentInvoice.vat +=
          +line.line_total_sum * line.tax_percentage * 0.01;
        this.currentInvoice.cda +=
          +line.line_total_sum * line.tax_percentage_cda * 0.01;
        this.currentInvoice.net_amount += +line.line_total_sum;
      });
    }
    this.lines = response.data.lines;
  }
  @action recalculateLines() {
    this.currentInvoice.weight = 0;
    this.currentInvoice.net_amount = 0;
    this.currentInvoice.vat = 0;
    this.currentInvoice.cda = 0;
    this.lines.forEach((line) => {
      this.currentInvoice.weight += +line.weight * line.quantity;
    });
    const propo =
      this.currentInvoice.total_freight / this.currentInvoice.weight;
    this.currentInvoice.gross_amount =
      parseFloat(this.currentInvoice.net_amount) +
      parseFloat(this.currentInvoice.total_tax);
    this.lines.forEach((line) => {
      line.freight_cost =
        isNaN(propo) || !isFinite(propo)
          ? 0
          : propo * line.weight * line.quantity;
      line.line_total_sum = +line.line_total  +line.handling_cost +line.freight_cost;
      this.currentInvoice.vat +=
        +line.line_total_sum * line.tax_percentage * 0.01;
      this.currentInvoice.cda +=
        +line.line_total_sum * line.tax_percentage_cda * 0.01;
      this.currentInvoice.net_amount += +line.line_total_sum;
    });
  }
  @action recalculateLines_handling() {
    this.currentInvoice.weight = 0;
    this.currentInvoice.net_amount = 0;
    this.currentInvoice.vat = 0;
    this.currentInvoice.cda = 0;
    this.lines.forEach((line) => {
      this.currentInvoice.weight += +line.weight * line.quantity;
    });

    const propo =
      this.currentInvoice.total_handling / this.currentInvoice.weight;
    this.currentInvoice.gross_amount =
      parseFloat(this.currentInvoice.net_amount) +
      parseFloat(this.currentInvoice.total_tax);
    this.lines.forEach((line) => {
      line.handling_cost =
        isNaN(propo) || !isFinite(propo)
          ? 0
          : propo * line.weight * line.quantity;
      line.line_total_sum = +line.line_total +line.handling_cost +line.freight_cost;
      this.currentInvoice.vat +=
        +line.line_total_sum * line.tax_percentage * 0.01;
      this.currentInvoice.cda +=
        +line.line_total_sum * line.tax_percentage_cda * 0.01;
      this.currentInvoice.net_amount += +line.line_total_sum;
    });
  }
  @action.bound fetchCurrentInvoiceError(error) {
    console.log(error);
  }
  @action fetchInvoices(name, search, sf, st, skip, count) {
    let query = `${process.env.REACT_APP_API}/invoices?name=${name}`;
    if (this.invoicesAcsSort) {
      query += `&asc=${this.invoicesAcsSort}`;
    }
    if (search) {
      query += `&search=${search}`;
    }
    if (sf) {
      query += `&sf=${sf}`;
    }
    if (st) {
      query += `&st=${st}`;
    }
    if (skip) {
      query += `&skip=${skip}`;
    }
    if (count) {
      query += `&count=${count}`;
    }
    if (this.checkedStatuses) {
      query += `&status=${this.checkedStatuses}`;
    }
    return Axios.get(query, this.rootStore.config()).then(
      this.fetchInvoicesSuccess,
      this.fetchInvoicesError
    );
  }
  @action.bound fetchInvoicesSuccess(response) {
    if (response.data.count) {
      this.invoicesCount = response.data.count;
    }
    if (response.data.invoices && response.data.invoices.length > 0) {
      response.data.invoices.forEach((invoice) => {
        invoice.gross_amount = +(
          invoice.net_amount +
          +parseFloat(invoice.vat) +
          parseFloat(invoice.cda)
        ).toFixed(2);
        invoice.total_tax_sum = (
          +parseFloat(invoice.vat) + +parseFloat(invoice.cda)
        ).toFixed(2);
        invoice.total_tax_sum_usd = +(
          invoice.total_tax_sum / invoice.rate
        ).toFixed(2);
        invoice.gross_amount_usd = +(
          invoice.gross_amount / invoice.rate
        ).toFixed(2);
        invoice.total_tax_usd = +(invoice.total_tax / invoice.rate).toFixed(2);
        invoice.shipping_amount_usd = +(
          invoice.shipping_amount / invoice.rate
        ).toFixed(2);
      });
    }
    this.invoices = response.data.invoices;
  }
  @action.bound fetchInvoicesError(error) {
    console.log(error);
  }
  @action saveCurrentInvoice() {
    return Axios.post(
      `${process.env.REACT_APP_API}/invoice`,
      { invoice: this.currentInvoice },
      this.rootStore.config()
    ).then(this.saveCurrentInvoiceSuccess, this.saveCurrentInvoiceError);
  }
  @action.bound saveCurrentInvoiceSuccess(response) {
    this.rootStore.invoicesStore.setCurrentInvoice(
      response.data.invoice.number
    );
  }
  @action.bound saveCurrentInvoiceError(error) {
    console.log(error);
  }
  @action saveLines() {
    this.saveCurrentInvoice();
    return Axios.post(
      `${process.env.REACT_APP_API}/invoice/lines`,
      { lines: this.lines },
      this.rootStore.config()
    ).then(this.saveLinesSuccess, this.saveLinesError);
  }
  @action.bound saveLinesSuccess(response) {}
  @action.bound saveLinesError(error) {
    console.log(error);
  }
}

export default InvoicesStore;
