import { css } from '@emotion/core';
const tableHeader = css`
  border-right: 1px solid #d0d0d0;
  color: #404040 !important;
  font: 400 13px/19px 'Open Sans', 'Helvetica Neue', helvetica, arial, verdana,
    sans-serif;
  outline: 0;
  background-color: #fff;
  white-space: normal !important;
  div {
    white-space: normal;
  }
`;
const supCell = css`
  color: #337ab7;
  text-decoration: none;
`;
const nameIcon = css`
  margin-right: 10px;
`;
const buttons = css`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  background: #cde2ed;
`;
const select = css`
  width: 190px;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  margin-right: 50px;
  min-width: 30% !important;
  background: white;
`;
const searchButton = css`
  background: rgb(87, 181, 227);
  border: none;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  min-width: 10% !important;
  i {
    color: white;
  }
`;
const mainDiv = css`
  height: 90%;
`;

const tablePanel = css`
  justify-content: flex-start !important;
`;

const tableDiv = css`
  background-image: linear-gradient(to bottom, #cde2ed, #f3f3f3);
  padding: 8px 8px 0 8px;
  height: 95%;
  div:first-of-type {
    height: 100% !important;
  }
`;

const tableRows = css`
  & tbody tr:nth-of-type(odd) {
    background: white !important;
  }
  & tbody tr:nth-of-type(even) {
    background: #f6f6f6 !important;
  }
`;

export {
  tableHeader,
  supCell,
  nameIcon,
  buttons,
  select,
  searchButton,
  mainDiv,
  tablePanel,
  tableDiv,
  tableRows,
};
