import React from 'react';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as styles from './Orders.css';
import { formatDate } from '../../helpers/formatDate';
import OrderLines from './OrderLines';
import { Button } from '@material-ui/core';
import NotFound from '../not-found/NotFound';

@withRouter
@inject('rootStore')
@observer
class SingleOrder extends React.PureComponent {
  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    this.props.rootStore.ordersStore.setCurrentOrder(params.id);
  }

  render() {
    return this.props.rootStore.ordersStore.currentOrder.number ? (
      <div css={styles.mainDiv} className="small">
        <h1>{this.props.rootStore.ordersStore.currentOrder.number}</h1>
        <div css={styles.flexBody}>
          <div css={styles.flexColumn}>
            <div css={styles.columnItem}>
              <div>Order Number:</div>
              <p>
                <b>{this.props.rootStore.ordersStore.currentOrder.number}</b>
              </p>
            </div>
            <div css={styles.columnItem}>
              <div>Sent Date:</div>
              <p>
                <b>
                  {formatDate(
                    this.props.rootStore.ordersStore.currentOrder.sent_date
                  )}
                </b>
              </p>
              <div css={styles.columnItem}>
                <div>State:</div>
                <p>
                  <b>{this.props.rootStore.ordersStore.currentOrder.status}</b>
                </p>
              </div>
            </div>
          </div>
          <div css={styles.flexColumn}>
            <div css={styles.columnItem}>
              <div>Supplier:</div>
              <p>
                <b>{this.props.rootStore.ordersStore.currentOrder.supplier}</b>
              </p>
            </div>
            <div css={styles.columnItem}>
              <div>Supplier Currency:</div>
              <p>
                <b>
                  {this.props.rootStore.ordersStore.currentOrder.currency
                    ? this.props.rootStore.ordersStore.currentOrder.currency
                    : 'USD'}
                </b>
              </p>
            </div>
            <div css={styles.columnItem}>
              <div>Purchaser:</div>
              <p>
                <b>
                  {this.props.rootStore.ordersStore.currentOrder.purchaser_name}
                </b>
              </p>
            </div>
          </div>
          <div css={styles.flexColumn}>
            <div css={styles.columnItem}>
              <div>Total Value:</div>
              <p>
                <b>
                  {this.props.rootStore.ordersStore.currentOrder.total_value}
                </b>
              </p>
            </div>
            <div css={styles.columnItem}>
              <div>Invoiced Total Value:</div>
              <p>
                <b>
                  {this.props.rootStore.ordersStore.currentOrder.invoiced_value}
                </b>
              </p>
            </div>
          </div>
        </div>
        <OrderLines></OrderLines>
        <div css={styles.buttons}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              this.props.rootStore.ordersStore.generatePrint(
                this.props.rootStore.ordersStore.currentOrder.id
              );
            }}
          >
            Print
          </Button>
        </div>
      </div>
    ) : (
      <NotFound
        error={'Purchase Order is not yet available, please retry later'}
      ></NotFound>
    );
  }
}

export default SingleOrder;
