import React from 'react';
import { withRouter, Link } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import {
  Grid,
  Table,
  TableHeaderRow,
  TableColumnResizing,
  PagingPanel,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui';
import { PagingState, IntegratedPaging } from '@devexpress/dx-react-grid';
import { DataTypeProvider } from '@devexpress/dx-react-grid';
/** @jsx jsx */
import { css, jsx } from '@emotion/core';
import * as styles from './Suppliers.css';

const boolCell = (el) => {
  return el.value ? (
    <i className="fa fa-check"></i>
  ) : (
    <i className="fa fa-times"></i>
  );
};

const BoolCellProvider = (props) => (
  <DataTypeProvider formatterComponent={boolCell} {...props} />
);
const editCell = () => <i css={supCell} className="fa fa-edit"></i>;
const EditCellProvider = (props) => (
  <DataTypeProvider formatterComponent={editCell} {...props} />
);
const CustomizedHeader = ({ ...restProps }) => (
  <Table.Row {...restProps} css={styles.tableHeader} />
);
const CustomizedTable = ({ ...restProps }) => (
  <Table.Table {...restProps} css={styles.tableRows} />
);

const CustomizedPanel = ({ ...restProps }) => (
  <PagingPanel.Container {...restProps} css={styles.tablePanel} />
);

@withRouter
@inject('rootStore')
@observer
class SuppliersTable extends React.PureComponent {
  render() {
    const columns = [
      { name: 'supplierNumber', title: 'Supplier Number' },
      { name: 'supplierBNumber', title: 'Supplier Business Number' },
      { name: 'supplier', title: 'Supplier' },
      { name: 'preferredSupplier', title: 'Preferred Supplier' },
      { name: 'enable', title: 'Enable' },
      { name: 'isBillable', title: 'Is Billable' },
      { name: 'purchaserPricePreferred', title: 'Purchaser Price Preferred' },
      { name: 'discountRate', title: 'Discount Rate' },
      { name: 'emailAddress', title: 'Paperless Invoicing Email Address' },
      { name: 'punchoutEnabled', title: 'Punchout Enabled' },
      { name: 'defaultDepartment', title: 'Default Department' },
      { name: 'defaultAccount', title: 'Default Account' },
      { name: 'defaultCategory', title: 'Default Category' },
      { name: 'locationCode', title: 'Supplier Location Code' },
      {
        name: 'invoicingContacts',
        title: 'Supplier Paperless Invoicing Contacts',
      },
      { name: 'formatMapped', title: 'Invoice Format Mapped' },
      { name: 'contact', title: 'Supplier Contact' },
      { name: 'terms', title: 'Payment Terms' },
      { name: 'promote', title: 'Promote' },
    ];
    const columnWidths = [
      { columnName: 'supplierNumber', width: 120 },
      { columnName: 'supplierBNumber', width: 120 },
      { columnName: 'supplier', width: 240 },
      { columnName: 'preferredSupplier', width: 80 },
      { columnName: 'enable', width: 80 },
      { columnName: 'isBillable', width: 80 },
      { columnName: 'purchaserPricePreferred', width: 80 },
      { columnName: 'discountRate', width: 80 },
      { columnName: 'emailAddress', width: 120 },
      { columnName: 'punchoutEnabled', width: 80 },
      { columnName: 'defaultDepartment', width: 80 },
      { columnName: 'defaultAccount', width: 80 },
      { columnName: 'defaultCategory', width: 80 },
      { columnName: 'locationCode', width: 80 },
      {
        columnName: 'invoicingContacts',
        width: 80,
      },
      { columnName: 'formatMapped', width: 80 },
      { columnName: 'contact', width: 80 },
      { columnName: 'terms', width: 80 },
      { columnName: 'promote', width: 80 },
    ];

    const supplierCell = (el) => {
      return (
        <Link
          to="/home/suppliers/catalogues"
          onClick={() =>
            this.props.rootStore.suppliersStore.setCurrentSupplier(el.value)
          }
          css={styles.supCell}
        >
          <i css={styles.nameIcon} className="fa fa-star"></i>
          {el.value}
        </Link>
      );
    };

    const SupplierCellProvider = (props) => {
      return <DataTypeProvider formatterComponent={supplierCell} {...props} />;
    };
    return (
      <div css={styles.tableDiv}>
        <Grid
          rows={
            this.props.rootStore.suppliersStore.proccessedSuppliers
              ? this.props.rootStore.suppliersStore.proccessedSuppliers
              : []
          }
          columns={columns}
        >
          <PagingState defaultCurrentPage={0} pageSize={10} />
          <IntegratedPaging />
          <EditCellProvider for={['edit']}></EditCellProvider>
          <BoolCellProvider
            for={[
              'prefeerredSupplier',
              'enable',
              'isBillable',
              'purchaserPricePreferred',
              'punchoutEnabled',
              'formatMapped',
            ]}
          ></BoolCellProvider>
          <SupplierCellProvider
            func={'test'}
            for={['supplier']}
          ></SupplierCellProvider>
          <VirtualTable tableComponent={CustomizedTable} />
          <TableColumnResizing
            defaultColumnWidths={columnWidths}
            resizingMode={'widget'}
          />
          <TableHeaderRow rowComponent={CustomizedHeader} />
          <PagingPanel containerComponent={CustomizedPanel} />
        </Grid>
      </div>
    );
  }
}

export default SuppliersTable;
